import React from "react";
import "../../assets/css/style.css";

import "../../assets/css/bootstrap.min.css";

import "../../assets/css/navbar.css";
import isoCertiImage from "../../static/img/isoCertified.png";
import isoImage from "../../static/img/iso2700.png";
import sslSecure from "../../static/img/SSL-secure.png";

const FooterHome = () => {
  return (
    <div>
     <div
        className="container-fluid  footer fadeIn"
        data-wow-delay="0.1s"
        style={{
          backgroundColor: "#0e306d",
          color: "#fff",
          padding: "0px",
          margin: "0px",
          overflowY: 'hidden' ,
          overflowX: 'hidden'
        }}
      >
        <div className="container py-lg-5 py-2 ">
          <div className="row g-5 ">
            <div className="col-lg-3 col-md-6 cont">
              <h3 className="text-light mb-4" style={{ color: "#fff" }}>
                Contact
              </h3>

              <p className="mb-2" style={{ color: "#fff" }}>
                <i
                  className="fa fa-phone-alt  me-3"
                  style={{ color: "#fff" }}
                ></i>
                +91 9967 646 171
              </p>
              <p className="mb-2" style={{ color: "#fff" }}>
                <i
                  className="fa fa-envelope  me-3"
                  style={{ color: "#fff" }}
                ></i>
                info@taxosmart.com
              </p>
              <div className="d-flex pt-2 icons">
                <a
                  className="btn btn-square btn-outline-body me-1"
                  href="https://wa.me/7304343581"
                >
                  <i className="fab fa-whatsapp" style={{ color: "#fff" }}></i>
                </a>
                <a
                  className="btn btn-square btn-outline-body me-1"
                  href="https://www.facebook.com/people/Tax-O-Smart/100082747576751/"
                >
                  <i className="fab fa-facebook" style={{ color: "#fff" }}></i>
                </a>
                <a
                  className="btn btn-square btn-outline-body me-1"
                  href="https://www.instagram.com/taxo_smart"
                >
                  <i className="fab fa-instagram" style={{ color: "#fff" }}></i>
                </a>
                <a
                  className="btn btn-square btn-outline-body me-1"
                  href="https://www.youtube.com/@tax-o-smart4271"
                >
                  <i className="fab fa-youtube" style={{ color: "#fff" }}></i>
                </a>
                <a
                  className="btn btn-square btn-outline-body me-0"
                  href="https://in.linkedin.com/company/tax-o-smart-llp"
                >
                  <i
                    className="fab fa-linkedin-in"
                    style={{ color: "#fff" }}
                  ></i>
                </a>
                <a
                  className="btn btn-square btn-outline-body " 
                  // href="https://in.linkedin.com/company/tax-o-smart-llp"
                  href="https://x.com/Tax_O_Smart"
                  style={{ color: "#fff",width:"100%" ,marginRight:"35%",padding:"1%"}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  util">
              <h3 className="text-light mb-4" style={{ color: "#fff" }}>
                Utilities
              </h3>
              <a
                className="btn btn-link mb-lg-0 mb-4"
                href="\hsn"
                style={{ color: "#fff" }}
                onclick="topFunction()"
              >
                HSN
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-light mb-4">Quick Links</h3>
              <a
                className="btn btn-link"
                href="aboutus"
                d
                style={{ color: "#fff" }}
              >
                About Us
              </a>
              <a
                className="btn btn-link"
                href="contactus"
                style={{ color: "#fff" }}
              >
                Contact Us
              </a>
              <a
                className="btn btn-link"
                href="career"
                onclick="topFunction()"
                style={{ color: "#fff" }}
              >
                Career
              </a>
              {/* <a
                className="btn btn-link"
                ng-click="hctr.getNewArticles('category','newArticles')"
                href="blog"
                onclick="topFunction()"
                style={{ color: "#fff" }}
              >
                Blogs
              </a> */}
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-light mb-4">Certified</h3>

              <div className="position-relative mx-auto d-flex">
                <img
                  src={isoCertiImage}
                  className="isoImageCss"
                />
                &nbsp;&nbsp;&nbsp;
                <img src={isoImage} className="isoImageCss" />
                <img src={sslSecure} className="sslSecureImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterHome;
