import axios from "axios";
import React, { useState } from "react";
import "../assets/css/gstInvoice.css";
import iSmartImage from "../static/img/i-smart.png";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import icon5 from "../static/assets/img/icons/icon-5.png";
import icon6 from "../static/assets/img/icons/icon-6.png";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const GstInvoice = () => {
  // Structerd Data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: "GST Invoice Accounting Inventering",
    author: {
      "@type": "Person",
      name: "Taxosmart",
    },
    datePublished: "2024-10-21",
    image: "https://taxosmart.com/static/img/i-smart.png",
    articleBody:
      "A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more.",
    publisher: {
      "@type": "Organization",
      name: "TAXOSMART",
      logo: {
        "@type": "ImageObject",
        url: "https://taxosmart.com/public/static/img/taxosmartLogo.jpg",
      },
    },
    description:
      "A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more.",
    keyword:
      "GST Billing Software, Goods and Services Tax, GST Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice",
  };

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      // new code for captcha
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <html>
        <body>
          <head>
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <title>
                GST Invoice, Accounting And Inventory Management Software
              </title>
              <meta
                name="description"
                content="A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more."
              />
              <meta
                name="keywords"
                content="GST Billing Software, Goods and Services Tax, GST Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice."
              />
              <meta name="author" content="Taxosmart" />

              {/* <!-- Open Graph (for Facebook) --> */}
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content="GST Invoice, Accounting And Inventory Management Software"
              />
              <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" />

              <meta
                property="og:description"
                content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations."
              />
              <meta
                property="og:url"
                content="https://www.taxosmart.com/gstInvoice"
              />
              <meta property="og:site_name" content="Taxosmart" />

              {/* <!-- Twitter Card --> */}
              <meta name="twitter:card" content="summary" />
              <meta
                name="twitter:title"
                content="GST Invoice, Accounting And Inventory Management Software"
              />
              <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" />

              <meta
                name="twitter:description"
                content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations."
              />
              <meta
                name="twitter:url"
                content="https://www.taxosmart.com/gstInvoice"
              />

              {/* <!-- Canonical Link --> */}
              <link
                rel="canonical"
                href="https://www.taxosmart.com/gstInvoice"
              />

              {/* <!-- Bing and other robots --> */}
              <meta name="robots" content="index, follow" />
            </Helmet>
          </head>
          <section
            id="hero"
            className="hero d-flex align-items-center wow fadeIn "
            data-wow-delay="0.1s"
          >
            <div className="container top0">
              <div className="Topbox" style={{ marginTop: "-5%" }}>
                <div className="topcontent">
                  <h1 className="InfoColor">
                    GST Invoice Accounting Inventering
                  </h1>
                  <p className="effort">
                    A GST invoice is a document which is supposed to contain all
                    the relevant details of a business transaction and both the
                    parties involved.
                  </p>
                  <div data-aos-delay="600">
                    <div className="text-center text-lg-center mobCol">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#demoModal"
                        data-bs-backdrop="false"
                        className="btn-get-started scrollto d-inline-flex align-items-center 
                        justify-content-center align-self-center"
                      >
                        <span className="getTouch">Get In Touch</span>
                        <i className="bi bi-arrow-right white"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="topimg"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={iSmartImage}
                    className="img-fluid gstInvoiceImageCss"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </section>

          <div class="container-xxl  pb-0" id="maincontainer">
            <div class="container">
              <div
                class="text-center mx-auto  wow fadeInUp ourService section-header"
                data-wow-delay="0.1s"
              >
                <h4 class="ourServiceColor">Our Services</h4>
                <h2 class="  gstFont">
                  INFORMATION ABOUT GST INVOICE,ACCOUNTING AND INVENTORY
                  MANAGEMENT
                </h2>
              </div>
            </div>
          </div>

          <section id="about" class="about gstTopClass">
            <div class="container">
              <div class="row gx-0" style={{ marginTop: "-10%" }}>
                <div class="col-lg-6 d-flex flex-column justify-content-center">
                  <div class="content">
                    <h2 className="gstColorInvoice">What Is GST Invoice?</h2>
                    <p>
                      A GST invoice is a document which is supposed to contain
                      all the relevant details of a business transaction and
                      both the parties involved. It must comprise of the product
                      name, description, quantity, details of the supplier and
                      the purchaser, terms of sale, rate charged, discounts,
                      etc.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 d-flex align-items-center">
                  <img src={iSmartImage} alt="" class="gstImage" />
                </div>
              </div>
            </div>
          </section>

          <div class="container-xxl  pb-0" id="maincontainer" style={{ marginTop: "5%" }}>
            <div class="container">
              <div
                class="text-center mx-auto  wow fadeInUp fadeCss section-header"
                data-wow-delay="0.1s"
              >
                <h4 class=" stepsColor">Steps</h4>
                <h2 class="gstFont mb-4 stepsInvoiceColor">
                  Steps For Invoice Managing Process
                </h2>
              </div>
              <div class="container-xxl " id="maincontainer">
                <div class="container">
                  <div class="row g-5">
                    <div
                      class="col-lg-6 wow fadeInUp fadeCss"
                      data-wow-delay="0.1s"
                    >
                      <div class="row g-4">
                        <div class="col-12">
                          <div class="d-flex align-items-center alignColumn mobCol">
                            <img class="flex-shrink-0" src={icon1} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid">Synchronize invoices/challans by: </h3>
                              <p class="mb-0">
                                Integrating existing ERP with our solution OR
                                Importing Excel files{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon2} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 >Search Invoices by GSTIN:</h3>
                              <p class="mb-0">Customer Name, Invoice No</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon3} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3>Input the additional:</h3>
                              <p class="mb-0">transporter details</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 wow fadeInUp fadeCss"
                      data-wow-delay="0.5s"
                    >
                      <div class="row g-4">
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon4} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3>Create, Modify:</h3>
                              <p class="mb-0">Print e-Way bills</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon5} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3>Input the additional:</h3>
                              <p class="mb-0">transporter details</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-xxl " id="maincontainer">
            <div class="container">
              <div
                class="text-center mx-auto  wow fadeInUp fadeCss section-header"
                data-wow-delay="0.1s"
              >
                <h4 class=" stepsColor">Steps</h4>
                <h2 class=" mb-4 gstFont stepsInvoiceColor">
                  Steps For Invoice Generation Process
                </h2>
              </div>
              <div class="container-xxl " id="maincontainer">
                <div class="container">
                  <div class="row g-5">
                    <div
                      class="col-lg-6 wow fadeInUp fadeCss"
                      data-wow-delay="0.1s"
                    >
                      <div class="row g-4">
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon1} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid">Invoicing : </h3>
                              <p class="mb-0">
                                Generate GST compliant invoices{" "}
                                Download, Print and Share invoices with your
                                custom{" "}
                                Maintain items catalog and contact directory
                                automatically{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon2} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid">
                                {" "}
                                File your GST returns in a single click :
                              </h3>
                              <p class="mb-0">
                                Save time in filing GST Returns by using already
                                prepared invoices on Tax-O-Smart{" "}
                                Identify errors before uploading to GSTN{" "}
                                Avoid penalties & claim correct Input Tax
                                Credits{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon3} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid"> Payments Tracking:</h3>
                              <p class="mb-0">
                                Record payments for invoices created in the
                                system
                                Track payments easily & get paid on time
                                Associate a single payment with multiple
                                invoices.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 wow fadeInUp fadeCss"
                      data-wow-delay="0.5s"
                    >
                      <div class="row g-4">
                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon4} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid">Stock Management </h3>
                              <p class="mb-0">
                                Manage stock for items you generate invoices
                                for{" "}
                                Automatically update stock while you create
                                invoices{" "}
                                Track available quantity of items in your
                                stock.{" "}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="d-flex align-items-center mobCol">
                            <img class="flex-shrink-0" src={icon5} alt="Icon" />
                            <div class="ms-lg-4 ms-0 ">
                              <h3 className="mobwid">
                                {" "}
                                Make effective business decisions based on real
                                time information{" "}
                              </h3>
                              <p class="mb-0">
                                Share invoices with customers & track payments
                                Track inventory & place orders with vendors on
                                time
                                Get real time business view of purchases,
                                sales & inventory
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="demoModal">
            <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
              <div className="modal-content sub-bg ">
                <div
                  className="modal-header subs-header modal-xxl"
                  style={{ width: "100%" }}
                >
                  <h3 className="modal-title headerModal" style={{ fontSize: "24px", whiteSpace: "nowrap" }}>
                    GST Invoice Accounting Inventering
                  </h3>
                  {/* <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                  >
                    &times;
                  </button> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <img
                      className="img-fluid"
                      src={iSmartImage}
                      style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                    />
                  </div>
                  <div className="heading-text text-center ">
                    <h4 className="shadow p-3 mb-5 ">
                      Ideal for Start-ups Going for Funding & Growing Business
                    </h4>
                  </div>
                  <div className="">
                    <form
                      onSubmit={handleSubmit}
                      style={{ border: "0px solid black" }}
                    >
                      <div className="form-group">
                        <input
                          className="form-control"
                          style={{ display: "none" }}
                          type="text"
                          id="type"
                          name="type"
                          value={(formData.type = "GST Invoice")}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mobile">Mobile</label>
                        <input
                          type="mobile"
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile"
                          className="form-control"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group" style={{ textAlign: "center", marginTop: "3%", display: "flex", justifyContent: "center" }}>
                        <ReCAPTCHA
                          sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                          onChange={handleCaptchaChange}
                        />
                      </div>
                      <div style={{ textAlign: "center", marginTop: "3%", display: "flex", justifyContent: "center" }}>
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={!captchaToken}
                          style={{ width: "200px", borderRadius: "6px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Success Modal */}


          <div >
            {isVisible && (
              <Modal show={successModalVisible} onHide={handleCloseSuccess} >
                <div
                  className="modalbox" style={{ borderRadius: "-1%" }}
                >
                  {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}

                  {/* Image centered above the message */}
                  <div className="succimglayout" >
                    <img src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
                  </div>

                  {/* Success message */}
                  <div style={{ textAlign: 'center' }}>
                    <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
                    <p>
                      Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
                    </p>
                  </div>

                  {/* OK button */}
                  <div style={{ textAlign: 'center', marginTop: '20px', display: "flex", justifyContent: "center" }}>
                    <Button
                      type="button"
                      onClick={closePop}
                      className="btndesign"
                      style={{ padding: '10px 20px', justifyContent: "center", fontSize: '16px', width: "60%", borderRadius: "6px", background: "#00ba00", outline: "none", color: "white", border: "none" }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </Modal>

            )}
          </div>

          {/* Error Modal */}
          <div>
            {isVisible && (
              <Modal show={errorModalVisible} onHide={handleCloseError}>
                <div
                  className="modalbox"

                >



                  {/* Image centered above the message */}
                  <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <img
                      src={remove}
                      alt="Error"
                      style={{ width: '20%' }}
                    />
                  </div>

                  {/* Error message */}
                  <div style={{ textAlign: 'center' }}>
                    <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
                    <p>
                      {error} {/* This will dynamically show the error message */}
                    </p>
                  </div>

                  {/* OK button */}
                  <div style={{ textAlign: 'center', marginTop: '20px', display: "flex", justifyContent: "center" }}>
                    <Button
                      type="button"
                      onClick={closePop}

                      style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        width: "60%",
                        borderRadius: "6px",
                        background: "#ff4d4d", // Error color, red
                        outline: "none",
                        color: "white",
                        border: "none",
                        justifyContent: "center"
                      }}
                    >
                      CLOSE
                    </Button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(structuredData)}
            </script>
          </Helmet>
        </body>
      </html>
    </div>
  );
};
export default GstInvoice;
