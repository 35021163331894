import axios from "axios";
import React, { useState } from "react";

import "../assets/css/Accountingbookkpg.css";

import AccBook from "../static/image/img/accbook.jpg";
import Bookimg from "../static/assetse/img/bookkeeping.png";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Bookpng from "../static/img/bookkeeping.png";
import ReCAPTCHA from "react-google-recaptcha";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const AccountingBookKpg = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Easily manage your Bookkeeping",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Looking for efficient bookkeeping services? TaxOSmart offers expert support for small businesses, helping you maintain accurate financial records and analyze transactions to reduce costs and taxes. Streamline your accounting with our network of CA/CSs and tax professionals.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Looking for efficient bookkeeping services? TaxOSmart offers expert support for small businesses, helping you maintain accurate financial records and analyze transactions to reduce costs and taxes. Streamline your accounting with our network of CA/CSs and tax professionals."
	}

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
         <meta property="og:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />
         <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
         <meta property="og:url" content="http://www.taxosmart.com/AccountingBookKpg" data-react-helmet="true" />
         <meta property="og:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
        
         <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 
         <meta name="twitter:site" content="@taxosmart.com" data-react-helmet="true" />
          <meta name="twitter:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
          <meta name="twitter:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />
        </Helmet>

      <main id="pageTop" className="pageTop">
        {/* copy code */}
        <section
          id="hero"
          className="hero d-flex align-items-center wow fadeIn top5"
          data-wow-delay="0.1s"
        >
          <div className="container top0">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">Easily manage your Bookkeeping</h1>
                <p className="fs-5"> Simplifying your Accounting Process</p>
                <div data-aos-delay="600">
                  <div className="text-center text-lg-start" id="resp">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      data-bs-backdrop="false"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span className="getTouch">Contact Us</span>
                      <i className="bi bi-arrow-right white"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
                <img src={Bookpng} className="img-fluid gstImageCss" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* copy code  */}

        {/* <!-- End Call To Action Section --> */}
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Bookimg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2>INFORMATION ABOUT BOOKKEEPING SERVICES</h2>
                </div>
                <p className="text-justify">
                  Bookkeeping is a systematic record of financial transactions
                  in the appropriate books of records. What it means is, whether
                  you make a sale or purchase something, a record will be
                  maintained of the transaction. This record will allow you to
                  easily keep a track of all your transactions and will help you
                  analyse how you can cut costs and reduce taxes. Bookkeeping is
                  a function of the accounts department of a business. However,
                  many small businesses do not have fully staffed and efficient
                  accounting departments and require the help of external
                  bookkeeping services. TaxOSmart can help your business
                  maintain its books through its network of CA/CSs and tax
                  experts.
                </p>
                <p className="text-justify">
                  Bookkeeping services are necessary for all businesses to
                  ensure accurate operational / financial information. Such
                  information is required by Management, Regulators, and
                  Investors. Also, it is a legal requirement for any business to
                  maintain an appropriate book of accounts to ensure that all
                  relevant taxes are paid and tax filings are made on time.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop">
          <div className="container">
            <header className="section-header">
              <h2 className="mediaFont">What Is Included In Our Package?</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4" style={{width:"100%"}}>
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts payable function</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts Reconciliation</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Payroll</h3>
                    </div>
                  </div>

                  <div className="col-md-6 mb-lg-0 mb-3">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Monthly Analysis</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2>Advantages Of Bookkeeping</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4" style={{width:"100%"}}>
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Cost-Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Reduced Tax Liabilities</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Time Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Peace of Mind</h3>
                    </div>
                  </div>

                  <div className="col-md-6 mb-lg-0 mb-3">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Higher Profits</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
            
            >
              <h3 className="modal-title headerModal" >
                Accounting Book Keeping
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Bookpng}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Accounting / Bookkeeping")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
    </div>
  );
};

export default AccountingBookKpg;
