import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/PublicltdCompany.css";
import PubLtdjpg from "../static/assetse/img/publicLimited.jpg";
import PubLtdjpg2 from "../static/assetse/img/publicPrivate.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";


const PublicLtdCompany = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Public Limited Company Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the requirements for Public Limited Company registration, including a minimum of 3 directors, 7 shareholders, and a paid-up capital of Rs 5 lakhs. Learn how to navigate the process and start trading your shares on the stock market today!",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the requirements for Public Limited Company registration, including a minimum of 3 directors, 7 shareholders, and a paid-up capital of Rs 5 lakhs. Learn how to navigate the process and start trading your shares on the stock market today!"
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null);
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
       <Helmet>
         <meta property="og:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />
         <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
         <meta property="og:url" content="http://www.taxosmart.com/PublicLtdCompany" data-react-helmet="true" />
         <meta property="og:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
        
         <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 
         <meta name="twitter:site" content="@taxosmart.com" data-react-helmet="true" />
          <meta name="twitter:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
          <meta name="twitter:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />
        </Helmet>
      {/* <!-- ======= Call To Action Section ======= --> */}
      <section id="call-to-action" className="call-to-action">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3 className="fs-2">Public Limited Company Registration</h3>

              <a
                className="cta-btn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                href=""
                data-bs-backdrop="false"
              >
                Get In Touch
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      <section id="features" className="features mt-2">
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5 mt-0">
              <img src={PubLtdjpg} alt="" />
            </div>
            <div className="col-md-7">
            <div className="section-header">
              <h2 className="fs-2">What Is Public Limited Registration?</h2>
              </div>
              <p className="text-justify">
                Public Limited Companies are companies whose shares are traded
                in stock market or issues fixed deposits. For Public Limited
                Company Registration, the company must have minimum 3 Directors,
                7 Shareholders and Maximum 50 Directors and need Rs 5 Lakhs of
                Paid up Capital. A Public limited company have all the
                advantages of Private Limited Company and the ability to have
                any number of members, ease in transfer of shareholding and more
                transparency. Public Limited Registration is done through
                TaxOSmart.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check"></i> More preference is given to
                  public limited company in giving loan..
                </li>
                <li>
                  <i className="bi bi-check"></i> By following compliance public
                  limited company can list its shares on stock exchange..
                </li>
                <li>
                  <i className="bi bi-check"></i> Shareholders can transfer
                  their Shares with great ease.
                </li>
                <li>
                  <i className="bi bi-check"></i> Limits the liabilities of its
                  partners.
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- Features Item --> */}

          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-7">
            <div className="section-header">
              <h2 className="fs-2">
                Documents Required for Public Limited Registration
              </h2>
              </div>
              <ul>
                <li>
                  <i className="bi bi-check"></i> Copy of PAN Card of directors
                </li>
                <li>
                  <i className="bi bi-check"></i> Copy of Rent agreement (If
                  rented property).
                </li>
                <li>
                  <i className="bi bi-check"></i> Landlord NOC (Format will be
                  provided)
                </li>
                <li>
                  <i className="bi bi-check"></i> Passport size photograph of
                  directors
                </li>
                <li>
                  <i className="bi bi-check"></i> Electricity/ Water bill
                  (Business Place)
                </li>
                <li>
                  <i className="bi bi-check"></i> Copy of Aadhaar Card/ Voter
                  identity card
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img src={PubLtdjpg2} className="img-fluid" alt="" />
            </div>
          </div>
          {/* <!-- Features Item --> */}
        </div>
      </section>
      {/* <!-- End Features Section -->


      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                Public limited Compnay
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
                <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={PubLtdjpg}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Public Limited Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group"style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- jQuery library --> */}
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>

      {/* <!-- Latest compiled JavaScript --> */}
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    </div>
  );
};

export default PublicLtdCompany;
