import axios from "axios";
import React, { useState } from "react";
import "../assets/css/Section8reg.css";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import ISweb from "../static/assetse/img/indianSubsi.webp";
import ISjpg from "../static/image/img/indianSub.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

const IndianSubsidiary = () => {

  
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Indian Subsidiary",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Explore the opportunities for foreign companies to establish operations in India, one of the world's fastest-growing markets. Discover how foreign nationals and entities can invest and own companies, tapping into India's vast talent pool while adhering to the FDI Policy.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Explore the opportunities for foreign companies to establish operations in India, one of the world's fastest-growing markets. Discover how foreign nationals and entities can invest and own companies, tapping into India's vast talent pool while adhering to the FDI Policy.",
	  "keyword" : "Discover the opportunities for foreign companies to invest in India, one of the world's largest and fastest-growing markets. Learn about FDI policies, ownership options, and access to top-tier human resources."
  }
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", formData)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta  property="og:description" content="An Indian subsidiary allows foreign companies to establish operations in India, tapping into one of the fastest-growing markets. Learn about the requirements, including the need for at least one Indian director and compliance with FDI policy." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Indian Subsidiary" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/indianSubsidiary" data-react-helmet="true" />

        <meta name="keywords" content="Indian subsidiary, foreign investment, company incorporation in India, FDI policy, Indian director, business operations in India." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      {/* <!-- ======= Call To Action Section ======= --> */}
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontents">
              <h1
                className="display-1 animated slideInDown fs-1"
                style={{ color: "#0f316d" }}
              >
               Indian Subsidiary
              </h1>
             
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Call To Action</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={ISweb} className="img-fluid gstImageCss" alt=""  style={{width:"100%"}}/>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}
      <br /> <br />
      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services pb-0 imgalign" >
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 >Obtaining DSC and DIN:</h4>
                <p className="description">
                  Digital Signature Certificate(DSC) and Director Identification
                  Number(DIN) is requried for the proposed Directors of the
                  Private Limited Company. DIN and DSC can be obtained for the
                  proposed Directors within 5 to 7 days.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-pencil-square-o"></i>
              </div>
              <div>
                <h4 >Name Approval:</h4>
                <p className="description">
                  A minimum of one and a maximum of six proposed names must be
                  submitted to the MCA. Subject to availability, naming
                  guidelines and MCA processing time, Name Approval can be
                  obtained in 5 to 7 working days.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-space-shuttle" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >Company Incorporation</h4>
                <p className="description">
                  Incorporation documents can be submitted to the MCA along with
                  an application for incorporation. MCA will usually approve the
                  application for incorpration in 5 to 7 days, subject to their
                  processing time.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}
      <br />
      <br />
      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={ISweb} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first indianTop">
              <div className="section-header">
                <h2>What is Indian Subsidiary?</h2>
              </div>
              <p>
                There is a lot of interest among foreign companies to start
                their operations in India and tap into one of the largest and
                fast growing market, and have access to some of the best human
                resources in the world. A Foreign National (other than a citizen
                of Pakistan or Bangladesh) or an entity incorporated outside
                India (other than entity incorporated in Pakistan or Bangladesh)
                can invest and own a Company in India by acquiring shares of the
                company, subject to the FDI Policy of India. In addition, a
                minimum of one Indian Director who is a Indian Director and
                Indian Resident is required for incorporation of an Indian
                Company along with an address in India.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section -->

<!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0 mt-4">
        <div className="container">
          <div className="section-header">
            <h2>Advantages Of Indian Subsidiary</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-6 col-md-6">
              <div className="card card-hover">
                {/* <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div> */}
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Easy Transferability
                  </a>
                </h3>
                <p>
                  Shares of a company limited by shares are transferable by a
                  shareholder to any other person. Filing and signing a share
                  transfer form and handing over the buyer of the shares along
                  with share certificate can easily transfer shares.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-6 col-md-6">
              <div className="card card-hover">
                {/* <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div> */}
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Uninterrupted Existence
                  </a>
                </h3>
                <p>
                  A company has 'perpetual succession', that is continued or
                  uninterrupted existence until it is legally dissolved. A
                  company, being a separate legal person, is unaffected by the
                  death or other departure of any member but continues to be in
                  existence irrespective of the changes in membership.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-6 col-md-6">
              <div className="card card-hover">
                {/* <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div> */}
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Owning Property
                  </a>
                </h3>
                <p>
                  A company being a juristic person, can acquire, own, enjoy and
                  alienate, property in its own name. No shareholder can make
                  any claim upon the property of the company so long as the
                  company is a going concern.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-6 col-md-6">
              <div className="card card-hover">
                {/* <div className="card-img">
                  <img
                    src="assets/img/trucking-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div> */}
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Foreign Direct Investment
                  </a>
                </h3>
                <p>
                  100% Foreign Direct Investment (FDI) is allowed in many of the
                  sectors through Company type business entity without any prior
                  Government approval. FDI is not allowed in Proprietorship or
                  Partnership. LLP requires prior Government approval.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
      {/* Success Modal */}
      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px', fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px' }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                Indian Subsidiary
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={ISweb}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Indian Subsidiary")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="errorMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="http://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
    </div>
  );
};

export default IndianSubsidiary;
