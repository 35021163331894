import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from 'react-google-recaptcha';
import "../assets/css/pdfSighn.css";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import pdfSighnImage from "../static/image/img/pdfSignerBack.jpg";
import pdfImage from "../static/img/pdf 1.jpg";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import { Helmet } from 'react-helmet';
const PdfSighn = () => {
  // Structerd Data for SEO 
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "PDF Signer",
    "author": {
      "@type": "Person",
      "name": "Taxosmart"
    },
    "datePublished": "2024-10-21",
    "image": "https://taxosmart.com/static/assets/img/minslider/GST.png",
    "articleBody": "Unlock seamless document management with our PDF Signer tool, designed to simplify your workflow. Effortlessly sign multiple PDF files at once by selecting your input and output directories. Enhance your productivity and maintain document integrity with this intuitive solution that caters to both individual and professional needs. Streamline your signing process today!",
    "publisher": {
      "@type": "Organization",
      "name": "TAXOSMART",
      "logo": {
        "@type": "ImageObject",
        "url": "https://taxosmart.com/static/img/taxosmartLogo.jpg"
      }
    },
    "description": "Unlock seamless document management with our PDF Signer tool, designed to simplify your workflow. Effortlessly sign multiple PDF files at once by selecting your input and output directories. Enhance your productivity and maintain document integrity with this intuitive solution that caters to both individual and professional needs. Streamline your signing process today!"
  }
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Taxosmart | PDF Signer</title>
        <meta charset="ISO-8859-1" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {/* <meta name="description" content="The main function of PDF signer is to sign multiple PDF files by selecting input and output directories. Ideal for bulk signing of corporate documents, saving time and resources compared to traditional methods." />
        <meta name="keywords" content="PDF Signer, Digital Signature, PDF Signing Software, Bulk PDF Signer" />
        <meta name="author" content="Taxosmart" /> */}

        {/* <!-- Open Graph meta tags for Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="PDF Signer - Bulk Signing Made Easy" />
        <meta property="og:description" content="Sign multiple PDF files effortlessly with our PDF signer software, designed for bulk signing of corporate documents. Save time and resources." />
        {/* <meta property="og:image" content="/path/to/image.jpg" /> */}
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 

        <meta property="og:url" content="https://www.taxosmart.com/PdfSighn" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter meta tags --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 

        <meta name="twitter:url" content="https://www.taxosmart.com/PdfSighn" />
        <meta name="twitter:title" content="PDF Signer - Bulk Signing Made Easy" />
        <meta name="twitter:description" content="Sign multiple PDF files effortlessly with our PDF signer software, designed for bulk signing of corporate documents. Save time and resources." />

        {/* <!-- Bing meta tags --> */}

        <meta name="robots" content="index, follow" />

        {/* <!-- Canonical link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/ItrNon" />



      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontents">
              <h1 className="InfoColor">PDF Signer</h1>
              <p className="effort">
                The main function of pdf signer is you can sign multiple pdf
                files by selecting input and output directory.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={pdfSighnImage}
                className="img-fluid gstImageCss pdfImage"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp section-header"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h2
              className="gstFont display-5 mb-4"
              style={{ color: "#132f6b" }}
            >
              INFORMATION ABOUT PDF SIGNER
            </h2>
          </div>
        </div>
      </div>

      <section id="about" className="about" style={{ marginTop: "-7%" }}>
        <div className="container">
          <div className="row gx-0" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="col-lg-6 d-flex flex-column justify-content-center" style={{gap:"10px"}}>
              <div className="content">
                <h2 className="lg:mt-0 mt-5 "  style={{ color: "#132f6b", display: "flex", alignItems: "center", justifyContent: "center" }}>PDF Signer</h2>
                <p className="" style={{paddingRight:"70px"}}>
                  The main function of pdf signer is you can sign multiple pdf
                  files by selecting input and output directory. This is ideal
                  for bulk signing of a large number of corporate documents
                  rather than signing each one individually. don't lose precious
                  time and money on paper, postage, faxing, scanning or using
                  other document signing software that takes you hours to set up
                </p>
              </div>
            </div>

             <div className="col-lg-6 d-flex align-items-center">
              <img src={pdfImage} className="pdfImage" />
            </div> 
          </div>
        </div>
      </section>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h4 className="" style={{ color: "#5cb85c" }}>
              Benefits
            </h4>
            <div className="section-header">

              <h2
                className=" gstFont display-5 mb-4"
                style={{ color: "#132f6b" }}
              >
                What Are The Benefits Of PDF Signer
              </h2>
            </div>
          </div>
          <div className="container-xxl py-5" id="maincontainer">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img
                          className="flex-shrink-0 "
                          src={icon1}
                          alt="Icon1"
                        />
                        <div className="ms-4 ">
                          <h3 className="mobwid">They're fast </h3>
                          <p className="mb-0">
                          They're fast  instead of waiting for documents to be sent by
                            courier around the globe, your contracts can be
                            completed and delivered with digital signatures in a
                            matter of seconds.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid">They're secure</h3>
                          <p className="mb-0">
                            your contracts are even more secure because they're
                            not traveling around the globe to reach you ! you
                            don't have to meet people in person to sign a
                            document. also, if you receive a document that needs
                            to be signed it unburdens you.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid">They are less risky</h3>
                          <p className="mb-0">
                            because digital signatures use secure platforms,
                            there's less risk of fraud, and you can be sure of
                            greater document security. it also helps to protect
                            the environment as the number of trees required to
                            produce paper significantly reduces as the use of
                            electronic signatures gradually becomes universal.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp fadeCss section-header"
            data-wow-delay="0.1s"
          >
            <h2 className="gstFont display-5 " style={{ color: "#132f6b" }}>
              Speciality of our PDF Signer
            </h2>
          </div>
          <div className="container-xxl py-5" id="maincontainer">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid">Sign PDFs and documents. </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid"> High quality and free technical support.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid"> Support for multiple signatures and profile.</h3>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-center mobCol">
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="ms-4">
                          <h3 className="mobwid">Sign digitally on any page of document. </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

     
      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                PDF Signer
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={pdfSighnImage}
                  style={{ width: "100%", height: "100%", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group" >
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "PDF Signer")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfSighn;
