import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../assets/css/topicDetail.css";
// import { useNavigate } from 'react-router-dom';


const Blogs = () => {
  const [data, setData] = useState([]);
  const [img, setImg] = useState([]);
  const [error, setError] = useState("");
  // const navigate = useNavigate(); 

  const fetchData = async () => {
    try {
      const response = await axios.get("/apiblogs/homeBlogs");
      const result = response.data;
      setData(result.listTopics);
      setImg(result.listTopicImages);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  
  if (data.length === 0) return <p></p>;

  const combinedData = data.map((d) => {
    const imgItem = img.find((i) => i.topicId === d.id);
    return imgItem ? { ...d, ...imgItem } : d;
  });

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
         
        },
      },
    ],
  };

  return (
    <section className="blogs-section">
      <div className="container">
        <div className="header">
          <h4 className="section-title">THE Blogs</h4>
          <h1 className="section-heading"> INSIGHTS & NEWSLETTERS </h1>
        </div>

        {/* Slider */}
         <div>
          <Slider {...settings} className="slickDots"> 
          {combinedData.map((blog) => {
  console.log(blog.image); // Log to check if the base64 data is correct
  return (
    <div key={blog.id} className="blog-card-wrapper">
      <div className="blog-card mobCard">
        <a href={`/BlogDetail/${encodeURIComponent(blog.heading)}`} className="blog-link">
          <div className="image-container">
            <div
              className="image"
              style={{
                backgroundImage: `url('data:image/png;base64,${blog.image}')`,
              }}
            />
          </div>
          <div className="card-body">
            <div className="card-date">{blog.date}</div>
            <h2 className="card-title">{blog.heading}</h2>
            <p className="card-excerpt">{blog.excerpt}</p>
            <a className="btn-read-more" href={`/BlogDetail/${encodeURIComponent(blog.heading)}`}>
              Read more →
            </a>
          </div>
        </a>
      </div>
    </div>
  );
})}
          </Slider>
        </div> 
      </div>
    </section>
  );
};

export default Blogs;
