import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "../../assets/css/topicDetail.css";
import { useParams } from "react-router-dom";

const TopicDetail = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    type: "TDS", // Set default type value
  });
  const [data, setData] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState([]);   // Initialize as an empty array
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get("/apiblogs/homeBlogs");
      // console.log('Success:',response.data); // Log the entire response data
      const result = response.data; // Assuming JSON is the property containing the array
      console.log("result ", result);
      setData(result.listTopics);
      setImg(result.listTopicImages);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("YOUR_API_ENDPOINT", formData);
      setSuccess("Form submitted successfully!");
      setSuccessModalVisible(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Render component
  if (error) return <p>Error: {error}</p>;
  if (data.length == 0) return <p></p>; // Display loading state

  return (
    <div>
      <main class="container">
        <div class="row">
          <h2>Blog Post Title {id}</h2>
          <article class="col-md-8 blog-post card">
            <p class="text-muted">Posted on September 13, 2024 by Author</p>
            <img
              src="https://via.placeholder.com/800x400"
              class="img-fluid"
              alt="Blog Image"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              quam velit, scelerisque et ultrices nec, facilisis eget felis.
              Aenean vel tincidunt urna. Proin a vehicula libero. Phasellus
              vitae metus sit amet ante luctus facilisis. Cras non lacinia
              purus. Suspendisse nec condimentum risus. Etiam vitae efficitur
              nisi.
            </p>
            <a href="#" class="btn btn-primary">
              Read More
            </a>
          </article>
          <aside class="col-md-4">
            <div class="p-3 mb-3 bg-light rounded">
              <h4>Categories</h4>
              <ul class="list-unstyled">
                <li>
                  <a href="#">1. Others</a>
                </li>
                <li>
                  <a href="#">2. Indirect Tax</a>
                </li>
                <li>
                  <a href="#">3. Direct Tax</a>
                </li>
                <li>
                  <a href="#">4. Compliance Due Date Chart</a>
                </li>
                <li>
                  <a href="#">5. Company Low</a>
                </li>
                <li>
                  <a href="#">6. Audit</a>
                </li>
              </ul>
            </div>

            <div class="p-3 mb-3 bg-light rounded">
              <h4>Archives</h4>
              <ul class="list-unstyled">
                <li>
                  <a href="#">September 2024</a>
                </li>
                <li>
                  <a href="#">August 2024</a>
                </li>
                <li>
                  <a href="#">July 2024</a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
};

export default TopicDetail;
