import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/style.css";
import "../assets/css/TradeMarkReg.css";
import Llp from "../static/assetse/img/llp.jpg";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import LlpAnnual from "../static/image/img/llpAnnual.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const LlpCompliance = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "INFORMATION ABOUT LLP ANNUAL FILING",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Learn about the annual filing requirements for LLPs in India, including deadlines for the Annual Return and Statement of Account & Solvency. LLPs must adhere to a financial year from April 1 to March 31, with filings due on May 30 and October 30, respectively. Stay compliant with these key dates!",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Learn about the annual filing requirements for LLPs in India, including deadlines for the Annual Return and Statement of Account & Solvency. LLPs must adhere to a financial year from April 1 to March 31, with filings due on May 30 and October 30, respectively. Stay compliant with these key dates!"
	}

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };



  return (


    <div>
      <Helmet>
        <title> </title>
        <meta charset="utf-8" />
        <meta name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="LLPs in India must file their Annual Return within 60 days from the end of financial year and Statement of Account & Solvency within 30 days from the end of six months of close of financial year. Unlike Companies, it is compulsory for LLP's to maintain their financial year, as 1st April to 31st March. Therefore, LLP annual Filing is due on 30th May and the Statement of Account & Solvency is due on 30th October of each financial year." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="LLP Annual Filing" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/LlpCompliance" data-react-helmet="true" />

        
        <link href="static/css/style.css" rel="stylesheet" />
        <link href="static/css/font-awesome.css" rel="stylesheet" />
        <link rel="shortcut icon" type="image/x-icon" href="static/img/taxOsmart.ico" />
      </Helmet>
      <main id="pageTop" className="pageTop">
        {/* <!-- ======= Call To Action Section ======= --> */}
        <section id="call-to-action" className="call-to-action">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h3>LLP Annual Filing</h3>

                <a
                  className="cta-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#demoModal"
                  href=""
                  data-bs-backdrop="false"
                >
                  Call To Action
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Call To Action Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about ">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Llp} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2>INFORMATION ABOUT LLP ANNUAL FILING</h2>
                </div>
                <p>
                  LLPs in India must file their Annual Return within 60 days
                  from the end of financial year and Statement of Account &
                  Solvency within 30 days from the end of six months of close of
                  financial year. Unlike Companies, it is compulsory for LLP's
                  to maintain their financial year, as 1st April to 31st March.
                  Therefore, LLP annual Filing is due on 30th May and the
                  Statement of Account & Solvency is due on 30th October of each
                  financial year.
                </p>
                <p>
                  If the annual turnover exceeds Rs. 40 lakhs or if the
                  contribution exceeds Rs. 25 lakhs then, LLP's are not required
                  to audit their accounts.
                </p>
                <p>LLP Annual Filing is done through TaxOSmart</p>
                <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3" style={{color:"#012970"}}>What Is Included In Our Package?</h5>
                      <p>
                        Annual Return Preparation, Annual Return, Filling
                        Registration
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        <section className="lastsection">
          <div className="container">
            <div className="row">
              {/* <!-- ***** FAQ Start ***** --> */}
              <div className="col-md-6 offset-md-3">
                <div className=" pb-3">
                  <div className="FAQ-header">
                  <h2>Frequently Asked Questions</h2>
                  </div>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="faq" id="accordion">
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-1"
                          data-aria-expanded="true"
                          data-aria-controls="faqCollapse-1"
                        >
                          <span className="badge">1</span> What is Annual
                          Return?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-1"
                      className="collapse"
                      aria-labelledby="faqHeading-1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Annual return is a mandatory filing to be made by all
                          LLPs in India. The Annual return along with the
                          required documents must be filed with the Ministry of
                          Corporate Affairs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-2">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-2"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-2"
                        >
                          <span className="badge">2</span> What is the Statement
                          of Accounts and Solvency?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-2"
                      className="collapse"
                      aria-labelledby="faqHeading-2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          The Statement of Accounts and Solvency is a mandatory
                          filing that is required for all LLPs in India. The
                          Statement of Accounts and Solvency contains a
                          declaration on the state of solvency of the LLP by the
                          designated partners and also information related to
                          the statement of assets and liabilities and statement
                          of income and expenditure of the LLP.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-3">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-3"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-3"
                        >
                          <span className="badge">3</span>What is the due date
                          for filing LLP Annual Return?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-3"
                      className="collapse"
                      aria-labelledby="faqHeading-3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          The Annual return of an LLP is due within 60 days of
                          close of financial year. Annual return of an LLP is
                          due on or before May 30th of each financial year.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-4">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-4"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-4"
                        >
                          <span className="badge">4</span> What is the penalty
                          for not filing LLP Annual Return?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-4"
                      className="collapse"
                      aria-labelledby="faqHeading-4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Late filing or non-filing of LLP Annual Return or
                          Statement of Accounts and Solvency before the due date
                          will attract a penalty of Rs.100 for each day of
                          default.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* // <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
             
            >
              <h3 className="modal-title  headerModal" >
                LLP Compliance Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Llp}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Llp Compliance Reg")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LlpCompliance;
