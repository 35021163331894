import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/Section8reg.css";
import Sec8jpg from "../static/image/img/section8.jpg";
import Sec8Comjpg from "../static/assetse/img/section8Com.jpg";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Section8png from "../static/assetse/img/section8.png";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
const Section8Registeration = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Section 8 Company Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the benefits of a Limited Liability Partnership (LLP) under the Limited Liability Partnership Act 2008. Enjoy limited liability protection for owners while minimizing maintenance requirements. Learn how LLPs and Private Limited Companies shield directors from personal asset risks in case of defaults.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the benefits of a Limited Liability Partnership (LLP) under the Limited Liability Partnership Act 2008. Enjoy limited liability protection for owners while minimizing maintenance requirements. Learn how LLPs and Private Limited Companies shield directors from personal asset risks in case of defaults."
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        
        <meta property="og:description" content="A Section 8 Company is a Non-Profit Organization (NPO) focused on promoting arts, education, social welfare, and more. Learn about its registration process and how it operates like a limited company without using 'Limited' in its name." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Section 8 Company Registration" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/Section8Registeration" data-react-helmet="true" />

        <meta name="keywords" content="Section 8 company registration, Non-Profit Organization, NPO, charitable organization, company formation, social welfare, arts, education." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <!-- ======= Call To Action Section ======= --> */}
      <section
            id="hero"
            className="hero d-flex align-items-center wow fadeIn top5"
            data-wow-delay="0.1s"
          >
            <div className="container top0">
              <div className="Topbox">
                <div className="topcontent">
                  <h1 className="InfoColor text-lg-start" style={{fontSize:"60px"}} >
                  Section 8 Company Registration{" "}
                  </h1>
                  
                  <div data-aos-delay="600">
                    <div className="text-center text-lg-start">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#demoModal"
                        data-bs-backdrop="false"
                        className="btn-get-started scrollto
                         d-inline-flex align-items-center justify-content-center 
                         align-self-center"
                      >
                        <span className="getTouch"> Call To Action</span>
                        <i className="bi bi-arrow-right white"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="topimg"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={Sec8Comjpg}
                    className="img-fluid gstImageCss"
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </section>
      {/* <!-- End Call To Action Section -->
<!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about mt-0 pb-0">
          <div className="container-fluied" data-aos="">
            <div className="row gx-0">
              <div
                className="col-lg-12 d-flex flex-column justify-content-center"
                data-aos=""
                data-aos-delay="200"
              >
                <div className="container content">
                <div className="section-header">
                  <h2 className="fs-2">Who We Are</h2>
                  </div>
                  <header className="">
                    <p className="fs-5">What is LLP Registration?</p>
                    
                  </header>
                  <p className="mt-0">
                    LLP refers to Limited Liability Partnership and is governed
                    by Limited Liability Partnership Act 2008. Limited Liability
                    Partnership provides advantage of limited liability to its
                    owners and at the same time requires minimal maintenance.
                    The directors of a Private Limited Company have limited
                    liability to creditors. In case of default, banks /
                    creditors can only sell company's assets and not personal
                    assets of directors.
                  </p>
                  <p>
                    LLP Registration can be done through TaxOSmart in Delhi NCR,
                    Mumbai, Bengaluru, Chennai and all other Indian cities.
                  </p>
                  <p>
                    LLP is a Separate Legal Entity that gives the benefit of
                    limited liability of company plus the flexibility of
                    partnership, wherein no partner's is held liable on account
                    of other's partner misconduct and their rights and duties to
                    be governed by LLP agreement. The basic premise behind the
                    introduction of Limited Liability Partnership (LLP) is to
                    provide a form of business organization that is simple to
                    maintain while at the same time providing limited liability
                    to the owners.
                  </p>
                  <p>Choose LLP because:</p>
                  <ul>
                  <li className="fst-italic fontTextalign">
                  <i className="bi bi-check " style={{fontSize:"32px"}}></i><span style={{marginLeft:"-10px"}}>Dual advantages- Company and a Partnership.</span>
                    </li>
                    <li className="fst-italic fontTextalign">
                    <i className="bi bi-check" style={{fontSize:"32px"}}></i> <span style={{marginLeft:"-10px"}}>No partner will be responsible for other partner's misconduct.</span> 
                    </li>
                    <li  className="fst-italic fontTextalign">
                    <i className="bi bi-check" style={{fontSize:"32px"}}></i>  <span style={{marginLeft:"-10px"}}>Cheaper to incorporate than a Private Limited Company.</span>
                    </li>
                    <li className="fst-italic fontTextalign">

                    <i className="bi bi-check" style={{fontSize:"32px"}}></i> <span  style={{marginLeft:"-10px"}}>Limits the liabilities of its partners.</span> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features mt-0 pt-0">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img src={Sec8Comjpg} className="img-fluid mt-0" alt="" />
              </div>
              <div className="col-md-7 mt-7">
                <div className="section-header">

                <h2 className="fs-2">Section 8 Company Registration</h2>
                </div>
                <p className="fst-italic">
                  A Section 8 Company is an organization which is registered as
                  a Non-Profit Organization (NPO). NPO/company has its objective
                  of promotion of arts, commerce, charity, education, protection
                  of environment, science, social welfare, sports, research,
                  religion and intends to apply its profits, if any, or other
                  income in promoting its objectives. It functions exactly like
                  a limited company including all the rights and obligations
                  that come with such a company. However, it differs from a
                  company in one very crucial aspect, i.e. it cannot use the
                  words "Section 8" or "Limited" in its name.
                </p>
                <p>What Is Included In Our Package?</p>
                <ul>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> DIN For 2 Partners.
                  </li>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> Digital Signature For 2
                    Directors
                  </li>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> Name search and Approval
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Features Item --> */}

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img src={Section8png} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <div className="section-header">

                <h2 className="fs-2">Choose LLP because</h2>
                </div>
                <p className="fst-italic">
                  The income of the Company must be used to promote only
                  charitable objects and cannot pay any dividend to the members
                  of the company. The Central government provides an
                  incorporation certificate to all such companies and also
                  informs them about some restrictions and conditions. In case
                  they don't fulfil them, the Central government may also order
                  them to wind up the company. In case fraud objectives of the
                  Company are proved, legal action will be taken against all
                  officers of the Company. Section 8 Company Registration can be
                  done through TaxOSmart in Delhi NCR, Mumbai, Bengaluru,
                  Chennai and other Indian cities.
                </p>
                <p className="fst-italic">
                  In India, a non-profit organisation can be created by
                  registering a Society under the Societies Registration Acts or
                  registering a Trust or incorporating a Section 8 company with
                  a not for profit motive. Section 8 under the Companies Act,
                  2013 corresponds to the old Section 25 under the Companies
                  Act, 1956, which was popularly known as "Section 25 Company",
                  hence the name "Section 8 Company".
                </p>
              </div>
            </div>
            {/* <!-- Features Item --> */}
          </div>
        </section>
        {/* <!-- End Features Section -->

<!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop documentsReq">
          <div className="container" data-aos="">
            <div className="section-header">
              <h2>Documents Required for Section 8 Company Registration</h2>
            </div>
            <br />

            <div className="row">
              <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Digital Signature Certificate (if you already have it)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Director Identification Number (if you already have it)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Memorandum of Association</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Articles of Association</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        ID proof for members: (Aadhar Card, Passport, Voter ID)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3 "
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Address Proof</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}
      <section id="features" className="features featuresTop advantageTop">
        <div className="container" data-aos="">
          <div className="section-header pt-0">
            <h2>Advantages Of Section 8 Company Registration</h2>
          </div>
          <br />

          <div className="row">
            <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No Minimum Share Capital Requirement</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 "
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No Stamp Duty</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Easy Transfer of Ownership</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Clear Objectives for easy donations and loans</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Tax Benefits</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Easy to Manage</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 lg-mb-0 mb-3"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No need to use "Section 8" or "Limited" title</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      <section id="features" className="features stepTop pt-0 mt-0">
        <div className="container aos-init aos-animate" data-aos="">
          <br />
          <br />

          {/* <!-- Feature Icons --> */}
          <div
            className="row feature-icons aos-init aos-animate mt-0"
            data-aos=""
          >
            <div className="section-header pt-0 pb-0">
              <h2>Steps to Incorporate Section 8 Company Registration</h2>
            </div>
            <br />
            <div className="row mt-5">
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6 "
                    data-aos=""
                  >
                   
                    <div className="card card-hover ">
                      <h4>Application of DSC & DPIN:</h4>
                      <p>
                        First of all, the partners have to apply for Digital
                        signature and DPIN. Digital signature is an online
                        signature used of filing and DPIN refers to Directors
                        PIN number issued by MCA. If the directors already have
                        DSC and DPIN, then this step can be skipped.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6  "
                    data-aos=""
                    data-aos-delay="100"
                  >
                    
                    <div className="card card-hover" style={{height:"100%"}}>
                      <h4>Name Approval:</h4>
                      <p>
                        You have to provides 3 different name options to MCA of
                        which one will be selected. Names provided should
                        ideally be unique and suggestive of company business.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div className="card card-hover">
                      <h4>Approval of other authorities:</h4>
                      <p>
                        The Registrar of Companies may require the applicant to
                        furnish the approval or concurrence of any department,
                        regulatory body, appropriate authority, or Ministry of
                        the Central or State Government(s) in relation to the
                        work to be done.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div className="card card-hover" style={{height:"100%"}}>
                      <h4>Get Incorporation Certificate:</h4>
                      <p>
                        It typically takes 15- 25 days to form a Private limited
                        company and get the incorporation certificate.
                        Incorporation certification is a proof that company has
                        been created. It also includes your CIN number.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div className="card card-hover">
                      <h4>MOA & AOA submission:</h4>
                      <p>
                        Once the licence is obtained, one needs to draft
                        Memorandum of Association and Articles of Associate. But
                        the object of the company must always be a charitable
                        object. Both MOA and AOA are filed with the MCA with the
                        subscription statement.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div className="card card-hover" style={{height:"100%"}}>
                      <h4>Apply for PAN, TAN and Bank account:</h4>
                      <p>
                        Then you need to apply for PAN and TAN. PAN and TAN are
                        received in 7 working days. Post this, you can submit
                        the Incorporation certificate, MOA, AOA and PAN with
                        bank to open your bank account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <br />
      <br />
     

      {/* <!--/ End Single News -->

      <!-- Modal --> */}

      {/* Success Modal */}

  
      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal " >
                Section 8 Company Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Sec8Comjpg}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Section 8 Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default Section8Registeration;
