import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "../../assets/css/style.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/navbar.css";
import "../../assets/css/Career.css";

import Icon2 from "../../static/assets/img/icons/icon-2.png";
import Icon3 from "../../static/assets/img/icons/icon-3.png";
import Icon4 from "../../static/assets/img/icons/icon-4.png";

const Career = () => {
  const [isOpenRole1, setIsOpenRole1] = useState(false); // Track the state for Role 1
  const [isOpenRole2, setIsOpenRole2] = useState(false); // Track the state for Role 2
  const [isOpenRole3, setIsOpenRole3] = useState(false); // Track the state for Role 3

  const toggleAccordionRole1 = () => {
    setIsOpenRole1(!isOpenRole1); // Toggle Role 1
  };

  const toggleAccordionRole2 = () => {
    setIsOpenRole2(!isOpenRole2); // Toggle Role 2
  };

  const toggleAccordionRole3 = () => {
    setIsOpenRole3(!isOpenRole3); // Toggle Role 3
  };
  return (
    <div>
      <Helmet>
        <title>Careers at Tax-O-Smart</title>
        <meta name="description" content="Join Tax-O-Smart and explore endless career opportunities. Send your resume to careers@taxosmart.com." />
        <meta name="keywords" content="careers, jobs, Tax-O-Smart, employment opportunities" />
      </Helmet>

      <br />

      <div className=" animated animatedFadeInUp fadeInUp mn_menus">

        <div>

          <h1 className="pt-lg-4 pt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            Endless Opportunities  <strong >CAREERS</strong>
          </h1>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h2>Be A Part Of Our <strong style={{ color: "green" }}>SUCCESS</strong> </h2>
        </div>
        |<div className="mobCol" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h2>Interested Candidates can Send Your Resume to:</h2>
          <h2 style={{ color: "red" }}>careers@taxosmart.com</h2>
        </div>
      </div>

      {/* <div className="row animated animatedFadeInUp fadeInUp mobile">
        <br />
        <br />

        <h1>
          Endless Opportunities <strong className="carOpp">CAREERS</strong>
        </h1>

        <h2>Be A Part Of Our <span>SUCCESS</span> </h2>

        <h2>Interested candidates can Send Your Resume to:</h2>
        <h2>careers@taxosmart.com</h2>
      </div> */}

      <br />
      <div className="row ml-5 me-5">
        <div className="col-sm-12 theory">
          <center className="center">
            <i className="fa fa-quote-left"></i>
          </center>
          <center>

            <h1 className="gde">
              <span>Grow, Develop and Evolve</span>
            </h1>
          </center>
          <p>
            Tax-O-Smart is dedicated to developing highly innovative and creative
            products and services that execute total tax and accounting
            solutions to all kinds of business structures. At Tax-O-Smart,
            people are exposed to an intelligent and dynamic environment for
            professional excellence as well as personal growth. They have to
            work on the technologies and domains that translate into continual
            expansion of professional boundaries. We are here regulating
            etiquette, ethics, discipline, professional conduct, and standards
            within the organization.
          </p>
          <br />
        </div>
      </div>
      <br />

      <div className="bg-white">
        <b />
        <h1 className="ml-45">
          <center>Tax-O-Smart Benefits and Perks</center>
        </h1>
        <br />

        <div className="container-xxl py-5 ng-scope">
          <div className="container pt-5">
            <div className="row g-4">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon2} alt="Icon" />
                  </div>
                  <h3 className="mb-3 carDev">Career Development</h3>
                  <p className="mb-0 text-justify">
                    At Tax-O-Smart, you will be exposed to a wide space of
                    learning new methods of handling and dealing with the
                    official matters. We safeguard and enhance the professional
                    standing and interest of our team members. You will have an
                    amazing range of opportunities. You might become an expert
                    in a particular field or build proficiency across many
                    areas.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon3} alt="Icon" />
                  </div>
                  <h3 className="mb-3 carDev">Diversity & Inclusion</h3>
                  <p className="mb-0 text-justify">
                    We at Tax-O-Smart motivate our employees to open up their
                    minds and come up with new and innovative ideas that may
                    help the organization achieve its goals. We seek out
                    people from diverse backgrounds and encourage them to take
                    risks and approach challenges unconventionally.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon4} alt="Icon" />
                  </div>
                  <h3 className="mb-3">Best Times</h3>
                  <p className="mb-0 text-justify">
                    Though we have professional hours and days where we are
                    totally dedicated to serving our clients, we do not neglect
                    the peace and pleasure requirements of our team members and
                    employees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className=" bg-white" data-aos="fade-left">
          <div className="col-sm-12">
            <div className="container">
              <center>
                <h1>We are Hiring</h1>
              </center>
              <div
                className="mn_menu2 text-center mobCol"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h2>Interested candidates can Send Your Resume to:</h2>

                <h2 style={{ color: "red" }}>careers@taxosmart.com</h2>
              </div>

              {/* <div className="mobile">
                <h3>Interested candidates can send your resume to:</h3>

                <h5>careers@taxosmart.com</h5>
              </div> */}

              <br />
              <br />
              <div
                className=""
              >
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    onClick={toggleAccordionRole1} // Toggle on click
                  >
                    <h4 className="panel-title">
                      <a className="accordion-toggle">
                        <span className="accordion-title">Role 1: Full Stack Developer</span>
                        <i style={{ color: "#0f316d" }}
                          className={`fa ${isOpenRole1 ? "fa-minus" : "fa-plus"} ml-2`}
                        ></i>
                      </a>
                    </h4>
                  </div>
                  <div
                    className={`panel-body ${isOpenRole1 ? "show" : "collapse"}`}
                  >
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>Responsibilities:</strong>
                        <ul>
                          <li>Develop and maintain sister portals within the Tax-O-Smart ecosystem, ensuring integration, scalability, and performance optimization.</li>
                          <li>Collaborate with cross-functional technical teams to design, implement, and enhance features and user experiences.</li>
                          <li>Manage and contribute to Java-based projects currently in development, ensuring quality, timelines, and alignment with business objectives.</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>Strong proficiency in Java, along with expertise in HTML, CSS, JavaScript, jQuery, Ajax, JSON, MySQL, and other relevant technologies.</li>
                          <li>Excellent communication skills, with the ability to articulate technical concepts and collaborate effectively with team members.</li>
                          <li>Open to candidates from diverse educational backgrounds; practical experience and passion for technology are highly valued.</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    onClick={toggleAccordionRole2} // Toggle on click
                  >
                    <h4 className="panel-title">
                      <a className="accordion-toggle">
                        <span className="accordion-title"> Role 2: Bussiness Advisor</span>
                        <i style={{ color: "#0f316d" }}
                          className={`fa ${isOpenRole2 ? "fa-minus" : "fa-plus"} ml-2`}
                        ></i>
                      </a>
                    </h4>
                  </div>
                  <div
                    className={`panel-body ${isOpenRole2 ? "show" : "collapse"}`}
                  >
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>Responsibilities:</strong>
                        <ul>
                          <li>Engage with clients to understand their needs and provide tailored solutions.</li>
                          <li>Handle inbound communication, including calls and emails, addressing client inquiries and resolving issues promptly.</li>
                          <li>Convert leads into sales through effective communication and relationship-building.</li>
                          <li>Provide expert advice on the company’s services, helping clients make informed decisions.</li>
                          <li>Collaborate with the operations team to ensure smooth execution and delivery of services.</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>Strong communication and persuasion skills, with the ability to influence and build rapport with clients.</li>
                          <li>Proficiency in both **written and verbal** English communication, with an emphasis on clarity and professionalism.</li>
                          <li>Excellent interpersonal skills, capable of building relationships with clients and internal teams alike.</li>
                          <li>Fluency in Hindi (spoken) is essential to effectively engage with a diverse client base.</li>
                          <li>Familiarity with the dynamic and fast-paced nature of the startup environment, with a proactive attitude towards challenges and opportunities.</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    onClick={toggleAccordionRole3} // Toggle on click
                  >
                    <h4 className="panel-title">
                      <a className="accordion-toggle">
                        <span className="accordion-title"> Role 3: Paid Assistant</span>
                        <i style={{ color: "#0f316d" }}
                          className={`fa ${isOpenRole3 ? "fa-minus" : "fa-plus"} ml-2`}
                        ></i>
                      </a>
                    </h4>
                  </div>
                  <div
                    className={`panel-body ${isOpenRole3 ? "show" : "collapse"}`}
                  >
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>Responsibilities:</strong>
                        <ul>
                          <li>Prepare and file various tax returns, including ITR, TDS, Sales Tax Return, and Service Tax Return, ensuring compliance with relevant regulations.</li>
                          <li>Assist in company formation processes, including the setup of Pvt. Ltd. Companies, LLPs, Partnerships, OPCs, etc.</li>
                          <li>Handle basic registrations, including Trademark, Service Tax, Sales Tax, TAN, IEC, and more, ensuring timely and accurate processing.</li>
                          <li>Collaborate with the sales team to ensure seamless coordination and provide necessary documentation for client projects.</li>
                          <li>Engage with clients to collect required documents and ensure smooth processing of their requests.</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>Strong communication and persuasion skills, with the ability to effectively explain complex processes to clients.</li>
                          <li>Proficiency in English (written and verbal) with clear and professional communication.</li>
                          <li>Fluency in Hindi (spoken) is required to effectively communicate with a diverse client base.</li>
                          <li>Detail-oriented with the ability to manage multiple tasks and maintain accuracy in documentation and compliance.</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div id="" className="row animated animatedFadeInUp fadeInUp"></div>
      </div>
    </div>
  );
};

export default Career;
