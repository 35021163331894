import axios from "axios";
import React, { useState } from "react";
import "../assets/css/ChangeInDirector.css";
import CIDpng from "../static/img/changeinDirectorPNG.png";
import CIDjpg from "../static/assetse/img/changeInDirector.jpg";
import PVTltd from "../static/image/img/pvtLimited.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import SucWeb from "../static/img/Succ.webp";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
const ChangeInDirector = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Change in Directors",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "TaxOSmart simplifies the process of adding or removing Directors from your Company or Designated Partners from your LLP. Comply with legal requirements efficiently with our expert filing services.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "TaxOSmart simplifies the process of adding or removing Directors from your Company or Designated Partners from your LLP. Comply with legal requirements efficiently with our expert filing services."
	}

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };


  return (

    //   {/* <!-- End #main --> */}

    <div>
      <Helmet>
      <meta property="og:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />
         <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
         <meta property="og:url" content="http://www.taxosmart.com/ChangeInDirector" data-react-helmet="true" />
         <meta property="og:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
        
         <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 
         <meta name="twitter:site" content="@taxosmart.com" data-react-helmet="true" />
          <meta name="twitter:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
          <meta name="twitter:description"
            content="SMART Invoice, FORM 15CA , FORM 15CB, Tax, Auto Bulk Filing, online bussiness services provider , GST , Return filing , Tax Software, GST Software, TDS Software, Invoice Software,  Interest Income, Capital Gains, House Property, Business and Profession. Reconciliation, billing, Trademark registraion, RERA, Incorporation of Company"
            data-react-helmet="true" />

      </Helmet>
      <main className="pageTops">
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container changeTop">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">Change in Directors</h1>
                <p className="fs-5">
                  Simple Online Process to Add or Remove Directors
                </p>
                <div data-aos="" data-aos-delay="600">
                  <div className="resp text-center text-lg-start">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                      id="padd"
                    >
                      <span>Get In Touch</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg">
                <img src={CIDpng} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- End Call To Action Section --> */}
        <br />
        <br />
        <br />

        {/* <!-- ======= Featured Services Section ======= --> */}
        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-briefcase" aria-hidden="true"></i>
                </div>
                <div>
                  <h4 >Minimum Requirements</h4>
                  <p className="description">
                    To become the Director of a Company, a person must be at
                    least 18 years old and possess Director Identification
                    Number. Foreign Nationals can also become Directors of an
                    Indian Company.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-credit-card" aria-hidden="true"></i>
                </div>
                <div>
                  <h4 >Director Identification Number</h4>
                  <p className="description">
                    Director Identification Number or DIN is a unique number
                    allotted by the Ministry of Corporate Affairs for any person
                    who is a Director of a Company or proposes to be the
                    Director of a Company.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-id-card" aria-hidden="true"></i>
                </div>
                <div>
                  <h4 >Board of Director</h4>
                  <p className="description">
                    A Private Limited Company must have a minimum of two
                    Directors and up to a maximum of 15 Directors. The Directors
                    do not have to be shareholders. However, they have to be
                    over the age of 18.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Featured Services Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={CIDjpg} alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2>Change In Director</h2>
                </div>
                <p className="text-justify">
                  Directors are appointed by the shareholders of a Company for
                  the management of a Company. A Private Limited Company is
                  required to have a minimum of two Directors and a Limited
                  Company is required to have a minimum of three Directors. On
                  the other hand, a Limited Liability Partnership (LLP) has
                  Designated Partners and Limited Liability Partnership Act,
                  2008 requires each LLP to have a minimum of two Designated
                  Partners. Appointment or removal of a Director or Designated
                  Partners is thus required due to various reasons. TaxOSmart
                  can help you file the necessary filings to add or remove a
                  Director from your Company or add or remove a Designated
                  Partner from your LLP . Change in Directors is done by
                  TaxOSmart.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3" style={{color:"#0e306d"}}>What Is Included In Our Package?</h5>
                      <p>
                        Change In Directors application preparation , Change In
                        Directors Application Filling, ROC Registration Fees
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        <br />

       
      </main>
      {/* <!-- End #main --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                Change In Director
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CIDpng}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Change In Director")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
    </div>
  );
};

export default ChangeInDirector;
