import axios from "axios";
import React, { useState } from "react";
import opcImage from "../static/img/opc-removebg.png";
import "../assets/css/opc.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import { Helmet } from 'react-helmet';


const Opc = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "One Person Company Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the essentials of a One Person Company (OPC) under the Companies Act, 2013. Learn how OPCs provide limited liability protection for single-member businesses, along with their benefits, compliance requirements, and differences from Private Limited Companies.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the essentials of a One Person Company (OPC) under the Companies Act, 2013. Learn how OPCs provide limited liability protection for single-member businesses, along with their benefits, compliance requirements, and differences from Private Limited Companies."
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <meta property="og:title" content="What Is One Person Company (OPC)?" data-react-helmet="true" />

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="A One Person Company (OPC) is a company with a single member, introduced by the Companies Act, 2013. Enjoy limited liability and fewer compliances, making it easier for entrepreneurs to start their own ventures." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:url" content="http://www.taxosmart.com/Opc" data-react-helmet="true" />

        <meta name="keywords" content="One Person Company, OPC, company registration, Companies Act 2013, limited liability, business startup, entrepreneurship." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1 data-aos="" style={{ color: "#0f316d" }}>
                One Person Company Registration
              </h1>
              <p data-aos="" style={{ color: "black" }}>
                Ideal for Entrepreneur Who Have Alone Started A Venture.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={opcImage}
                className="img-fluid gstImageCss"
                style={{ borderRadius: "20%", marginLeft: "30%" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluied" data-aos="">
        <div class="row gx-0">
          <div
            class="col-lg-12 d-flex flex-column justify-content-center mt-0 mt-lg-5"
            data-aos=""
            data-aos-delay="200"
          >
            <div class="container content">
              <div class="section-header">
                <h2>What Is One Person Company?</h2>
              </div>
              <p style={{ marginTop: "0%", textAlign: "justify" }}>
                A One Person Company is a company with a single member. It was
                introduced by the Companies Act, 2013. OPC extends the concept
                of limited liability to a company run by a single person. It is
                similar in respects to a Private Limited Company with certain
                differences like fewer compliances and relaxation of certain
                restrictions. Thus, an OPC is subject to all the provisions of
                the Act like a Private Limited Company unless expressly
                excluded. The idea of One Person Company (OPC) in India was
                introduced to give a boost to entrepreneurs who have great
                potential to start their own venture by allowing them to create
                a single person company. Since, no intervention from any third
                party is seen, it makes it more beneficial. So, if you want to
                start up your own business, you don't have to worry about all
                the complex and tedious processes
              </p>
            </div>
          </div>
        </div>
      </div>

      <section id="features" class="features docRequired">
        <div class="container" data-aos="">
          <div class="row">
            <div class="col-lg-12 mt-lg-5 mt-0 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-10" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Only one member is required.</h3>
                  </div>
                </div>

                <div class="col-md-10" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Unaffected by death of member or change in ownership.
                    </h3>
                  </div>
                </div>

                <div class="col-md-10" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Easy to set up and maintain comparatively.</h3>
                  </div>
                </div>

                <div class="col-md-10" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Limits the liabilities of its members.</h3>
                  </div>
                </div>
                <div class="col-md-10 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Minimal Paperwork is required.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>

      <section id="features" class="features packageInclude pt-0 mt-0">
        <div class="container" data-aos="">
          <div class="section-header pt-0 mt-0">
            <h2>
              What Is Included In Our One Person Company Registration Package?
            </h2>
          </div>

          <div class="row">
            <div class="col-lg-12 mt-lg-5 mt-0 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>DIN For 1 Director.</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>DSC For 1 Director</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Name Search And Approval.</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>MOA/AOA</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>ROC Registration Fee</h3>
                  </div>
                </div>

                <div class="col-md-6 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Company PAN Card</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features docRequired mt-0 pt-0">
        <div class="container" data-aos="">
          <div class="section-header pt-0">
            <h2>Documents Required for OPC Registration</h2>
          </div>

          <div class="row" style={{ marginTop: "2%" }}>
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Copy of PAN Card of directors</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Passport size photograph of owner</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Copy of Aadhaar Card/ Voter identity card of owner</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Copy of Rent agreement (If rented property)</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Electricity/ Water bill (Business Place)</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Copy of Property papers(If owned property)</h3>
                  </div>
                </div>
                <div class="col-md-6 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Landlord NOC (Format will be provided)</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features docRequired mt-0 pt-0">
        <div class="container" data-aos="">
          <div class="section-header pt-0 mt-0">
            <h2>Advantages Of One Person Company</h2>
          </div>

          <div class="row">
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Limited Liability</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Separate Legal Entity</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Uninterrupted Existence</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Limits the liabilities of its members</h3>
                  </div>
                </div>
                <div class="col-md-6 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Borrowing capacity</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features advantageTop">
        <div class="container aos-init aos-animate" data-aos="">
          <div
            class="row feature-icons aos-init aos-animate"
            data-aos=""
            style={{ marginTop: "0%" }}
          >
             <div className="section-header">

            <h2>Steps For OPC Registration</h2>
             </div>

            <div class="row" style={{ marginTop: "0%" }}>
              <div class="col-xl-12 d-flex content">
                <div class="row align-self-center gy-4">
                  <div
                    class="col-md-6"
                    data-aos=""
                  >
                    <div className="card card-hover">
                      <h4>Obtain DSC and DIN:</h4>
                      <p>
                        The proposed directors must obtain a Digital Signature
                        Certificate(DSC) from 8 authorised DSC makers.
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-md-6 "
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <div className="card card-hover">
                      <h4>Reservation of name:</h4>
                      <p>
                        An application for the reservation of a suitable name
                        must be made in Form No. INC-1.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <div className="card card-hover">
                      <h4>Entrenchment Provisions:</h4>
                      <p>
                        In case the Articles of Association contain provisions
                        for entrenchment, the company must inform the Registrar
                        of such provisions in Form No.INC-2 at the time of
                        incorporation of the company or in case of existing
                        companies ( by amendment of the articles of
                        association), the same must be filed in Form No.MGT-14
                        within 30 days from the date of entrenchment of the
                        articles.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                  >
                    <div className="card card-hover">
                      <h4>
                        Signing of Memorandum and Articles of Association:
                      </h4>
                      <p>
                        The MOA and AOA of the company must be signed by the
                        sole member who is also the subscriber to the
                        memorandum, who must give details of his name, address,
                        description and occupation, if any, in the presence of
                        at least one witness who must also attest the signature
                        and must also sign and give his details.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <div className="card card-hover">
                      <h4>Application for incorporation of OPC:</h4>
                      <p>
                        An application must be filed, with the Registrar of
                        Companies(ROC) within whose jurisdiction the registered
                        office of the company is proposed to be situated, in
                        Form No.INC-2.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                  >
                    <div className="card card-hover">
                      <h4 className="stretched-link">Articles of Association:</h4>
                      <p>
                        Model Articles of Association have been provided in
                        Tables F, G, H, I and J of Schedule I, as are
                        applicable, and can be adopted by a company either in
                        totality or otherwise.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <div className="card card-hover">
                      <h4 className="stretched-link">Affidavit of Subscriber and the director:</h4>
                      <p>
                        The affidavit must be submitted by sole member who has
                        subscribed to the memorandum and named in the articles
                        in Form No.INC-9.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 "
                    data-aos=""
                  >
                    <div className="card card-hover">
                      <h4 >Particulars of Subscriber:</h4>
                      <p >
                        The sole member must file the particulars of
                        subscription with the Registrar at the time of
                        incorporation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}

      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px', fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                One Person Company Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>

            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={opcImage}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Opc Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group"style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opc;
