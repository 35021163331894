import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import "../../assets/css/style.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/navbar.css";
import SucWeb from "../../static/img/Succ.webp";
import tdsPageImage from "./tds img.jpg"
import Button from 'react-bootstrap/Button';
import successtick from "../../assets/img/successtick.png";
import remove from "../../assets/img/remove.png";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    service: '',
    comment: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/apiCustomerDetail/', formData)
      .then(response => {
        setSuccess('Registration successful!');
        setSuccessModalVisible(true);
        setError('');
        setFormData({
          type: 'ContactUs',
          name: '',
          email: '',
          mobile: '',
          service: '',
          comment: ''
        });
      })
      .catch(error => {
        setError('Error during registration: ' + error.message);
        setErrorModalVisible(true);
        setSuccess('');
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us - Taxosmart</title>
        <meta name="description" content="Get in touch with us for inquiries and support." />
        <meta name="keywords" content="contact, inquiries, support, Taxosmart" />
      </Helmet>

      <div>
      
      <section
            id="hero"
            className="hero d-flex align-items-center wow fadeIn top5"
            data-wow-delay="0.1s"
          >
            <div className="container top0">
              <div className="Topbox">
                <div className="topcontent">
                  <h1 className="InfoColor text-lg-start" style={{fontSize:"60px"}} >
                 Contact US{" "}
                  </h1>
                 
                </div>
                <div
                  className="topimg"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={tdsPageImage}
                    className="img-fluid gstImageCss"
                    alt=""
                    style={{ width: "70%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </section>

        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="fontcol">
                <h4 className="section-title text-end">Contact Us</h4>
                <h1 className="display-5 mb-4 fs-1">We'd love to hear from you!</h1>
                </div>
                <div className="row g-4">
                  <div className="col-12">
                    <div className="d-flex info">
                      <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light w-65 h-65">
                        <i className="fa fa-2x fa-phone-alt"></i>
                      </div>
                      <div className="ms-4 expertInfo">
                        <p className="mb-2">TALK TO AN EXPERT</p>
                        <h3 className="mb-0">+91 9967 646 171</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex info">
                      <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light">
                        <i className="fa fa-2x fa-envelope-open"></i>
                      </div>
                      <div className="ms-4 expertInfo">
                        <p className="mb-2">FOR QUERIES</p>
                        <h3 className="mb-0">info@taxosmart.com</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <form onSubmit={handleSubmit} style={{ border: '0px solid black' }}>
                  <div className="row g-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.type = 'ContactUS'}
                      onChange={handleChange}
                      required
                      style={{ display: 'none' }}
                      placeholder="Your Name"
                    />
                    <div className="col-12 col-sm-6 mb-lg-0 mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col-12 col-sm-6 mb-lg-0 mb-2">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email Id"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 mb-lg-0 mb-2">
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Mobile"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 mb-lg-0 mb-2">
                    <input
                        type="text"
                        className="form-control"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                        placeholder="Write Service"
                      />
                    </div>
                    <div className="col-12 mb-lg-0 mb-2">
                      <textarea
                        className="form-control"
                        rows="5"
                        name="comment"
                        placeholder="Message"
                        value={formData.comment}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="col-12 subBtn">
                      <button className="btn btn-success w-100 py-3 justify-content-center" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Success Modal */}
        <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
      </div>
    </HelmetProvider>
  );
};

export default ContactUs;
