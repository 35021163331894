import React, { useState } from "react";
import axios from "axios";

import gstImage from "../static/assets/img/minslider/GST.png";
import "../assets/css/gstReconcile.css";

import "../assets/css/bootstrap.min.css";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import "../static/assets/css/styleMin.css";
import "../static/assets/css/mainMin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
const GstReconcile = () => {
  // Structerd Data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline:
      "Smart GST Reconciliation Software | Streamline Your GST Compliance",
    author: {
      "@type": "Person",
      name: "Taxosmart",
    },
    datePublished: "2024-10-21",
    image: "https://taxosmart.com/static/assets/img/minslider/GST.png",
    articleBody:
      "Discover the Smart GST Reconciliation System by Taxosmart, designed with cutting-edge technology to ensure complete compliance with GST provisions. Achieve 100% invoice reconciliation with GSTR 2A, GSTR 3B, and your Purchase Register effortlessly.",
    publisher: {
      "@type": "Organization",
      name: "TAXOSMART",
      logo: {
        "@type": "ImageObject",
        url: "https://taxosmart.com/static/img/taxosmartLogo.jpg",
      },
    },
    description:
      "GSTR2A Reconciliation, PAN Verification, GSTIN Verification, GST Payment, GSTR2A Reconciliation, GSTR1 Summary, GSTR3B Summary, GSTR9 Summary, GSTR1 Filing, GSTR3B Filing, GSTR9 Filing.",
  };

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      // new code for captcha
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null);
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <html>
        <body>
          <head>
            <Helmet>
              <title>
                Smart GST Reconciliation Software | Streamline Your GST
                Compliance
              </title>
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
                       <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 

              <meta
                name="description"
                content="Discover the Smart GST Reconciliation System by Taxosmart, designed with cutting-edge technology to ensure complete compliance with GST provisions. Achieve 100% invoice reconciliation with GSTR 2A, GSTR 3B, and your Purchase Register effortlessly."
              />
              <meta
                name="keywords"
                content="GSTR2A Reconciliation, PAN Verification, GSTIN Verification, GST Payment, GSTR2A Reconciliation, GSTR1 Summary, GSTR3B Summary, GSTR9 Summary, GSTR1 Filing, GSTR3B Filing, GSTR9 Filing."
              />
              <meta name="author" content="Taxosmart" />

              {/* <!-- Open Graph (for Facebook) --> */}
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content="Smart GST Reconciliation Software | Streamline Your GST Compliance"
              />
              <meta
                property="og:description"
                content="Discover the Smart GST Reconciliation System by Taxosmart, designed with cutting-edge technology to ensure complete compliance with GST provisions."
              />
              <meta
                property="og:url"
                content="https://www.taxosmart.com/GstReconcile"
              />
              <meta property="og:site_name" content="Taxosmart" />

              {/* <!-- Twitter Card --> */}
              <meta name="twitter:card" content="summary" />
              <meta
                name="twitter:title"
                content="Smart GST Reconciliation Software | Streamline Your GST Compliance"
              />
                       <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 

              <meta
                name="twitter:description"
                content="Discover the Smart GST Reconciliation System by Taxosmart, designed with cutting-edge technology to ensure complete compliance with GST provisions."
              />
              <meta
                name="twitter:url"
                content="https://www.taxosmart.com/GstReconcile"
              />

              {/* <!-- Canonical Link --> */}
              <link
                rel="canonical"
                href="https://www.taxosmart.com/gstReconcilation"
              />

              {/* <!-- Bing and other robots --> */}
              <meta name="robots" content="index, follow" />
            </Helmet>
          </head>
          <section
            id="hero"
            className="hero d-flex align-items-center wow fadeIn top5"
            data-wow-delay="0.1s"
          >
            <div className="container top0">
              <div className="Topbox">
                <div className="topcontent">
                  <h1 className="InfoColor">
                    Information About GST Reconciliation Software
                  </h1>
                  <p className="effort">
                    Effortlessly reconcile GST transactions with precision and
                    efficiency using our specialized software, ensuring
                    compliance and accuracy for your business financial records.
                  </p>
                  <div data-aos-delay="600">
                    <div className="text-center text-lg-start">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#demoModal"
                        data-bs-backdrop="false"
                        className="btn-get-started scrollto
                         d-inline-flex align-items-center justify-content-center 
                         align-self-center"
                      >
                        <span className="getTouch">Get In Touch</span>
                        <i className="bi bi-arrow-right white"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="topimg"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={gstImage}
                    className="img-fluid gstImageCss"
                    alt=""
                    style={{ width: "70%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </section>

          <main id="main">
            <section id="about" className="about gstTop">
              <div className="container" data-aos="">
                <div className="row gx-0">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center"
                    data-aos=""
                    data-aos-delay="200"
                  >
                    <div className="container content pt-5 headerCenter section-header" >
                      <h2 style={{ marginTop: "3%" }}>
                        INFORMATION ABOUT GST RECONCILIATION SOFTWARE.
                      </h2>
                      <p className="text-justify">
                        GST is an Intelligent GST Reconciliation System built in
                        state-of-the-art technology, complying with GST
                        provisions prescribed as per GST Act. Its a complete GST
                        Reconciliation system which ensures 100% invoice
                        reconciliation with GSTR 2A, GSTR3B and Purchase
                        Register
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center">
                <img src={gstImage} alt="" className="tdsImage" />
              </div>
                </div>
              </div>
            </section>

            <section
              id="features"
              className="features"
              style={{ marginTop: "-5%" }}
            >
              <div
                className="container"
                data-aos=""
                style={{ marginTop: "-1%" }}
              >
                <header class="section-header">
                  <h2 style={{ marginTop: "8%" }}>Why Reconciliation</h2>
                </header>

                <div className="row">
                  <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                    <div className="row align-self-center gy-4">
                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Claim correct amount of Input Tax Credit</h3>
                        </div>
                      </div>


                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            Form 16, 12BA, 16A and 27D (TDS/TCS Certificates)
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="400"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Avoid Notices and Audit issues.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="500"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Make corrections to your book of accounts</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Contact your vendors through the software</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Manage the entire reconciliation process</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="features"
              className="features gstTop"
              style={{ marginTop: "-5%" }}
            >
              <div className="container" data-aos="">
                <header className="section-header">
                  <h2 style={{ marginTop: "8%" }}>Reconciliation Process</h2>
                </header>

                <div className="row">
                  <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                    <div className="row align-self-center gy-4">
                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            GSTR 2A Download and Client's Purchase Register.
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            Sophisticated Reconciliation System (SmartRec)
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="400"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            Run Advanced Filters for resolving near matches.
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="500"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            Bucketize Differences into multiple categories.
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Contact your vendors through the software</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Manage the entire reconciliation process</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            Connect with Vendors and internal teams for mismatch
                            resolution.
                          </h3>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Request Vendor for Correction</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Correcting in own Accounting System.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="700"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Carry Forward to Next Month.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="features"
              className="features gstTop"
              style={{ marginTop: "-5%" }}
            >
              <div className="container" data-aos="">
                <header className="section-header">
                  <h2 style={{ marginTop: "8%" }}>
                    Point Taken care for Reconciliation
                  </h2>
                </header>

                <div className="row">
                  <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                    <div className="row align-self-center gy-4">
                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>
                            List of invoices from your purchase register that
                            matched with the supplier's uploads.
                          </h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="300"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>List of invoices that failed.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="400"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Invoice-wise reasons for mismatch.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="500"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Total ITC claimable for a particular month.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>ITC you under-claimed in a particular month.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>ITC you over-claimed in a particular month.</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Compliance history of vendors.</h3>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Request Vendor for Correction</h3>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="zoom-out"
                        data-aos-delay="600"
                      >
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <h3>Correcting in own Accounting System.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <div className="modal fade" id="demoModal">
            <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
              <div className="modal-content sub-bg ">
                <div
                  className="modal-header subs-header modal-xxl"
                  style={{ width: "100%" }}
                >
                  <h3 className="modal-title headerModal" >
                  GST Reconciliation SOFTWARE
                  </h3>
                  {/* <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                  >
                    &times;
                  </button> */}
                   <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <img
                      className="img-fluid"
                      src={gstImage}
                      style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                    />
                  </div>
                  <div className="heading-text text-center ">
                    <h4 className="shadow p-3 mb-5">
                      Ideal for Start-ups Going for Funding & Growing Business
                    </h4>
                  </div>
                  <div className="">
                    <form
                      onSubmit={handleSubmit}
                      style={{ border: "0px solid black" }}
                    >
                      <div className="form-group">
                        <input
                          className="form-control"
                          style={{ display: "none" }}
                          type="text"
                          id="type"
                          name="type"
                          value={(formData.type = "GST Reconcile")}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mobile">Mobile</label>
                        <input
                          type="mobile"
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile"
                          className="form-control"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group" style={{ textAlign: "center", marginTop: "3%", display: "flex", justifyContent: "center" }}>
                        <ReCAPTCHA
                          sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                          onChange={handleCaptchaChange}
                        />
                      </div>
                      <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={!captchaToken}
                          style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Success Modal */}

    
          <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(structuredData)}
            </script>
          </Helmet>
        </body>
      </html>
    </div>
  );
};
export default GstReconcile;
