import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/topicDetail.css";
import { Link } from "react-router-dom";

const NewsArticle = () => {
  const [data, setData] = useState([]); // Blog data state
  const [error, setError] = useState(""); // Error state for API calls
  const [cat, setCat] = useState([]); // Categories state
  const [img, setImg] = useState([]); // Images for each blog
  const [activeCategory, setActiveCategory] = useState(null);

  const { categoryId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);


  const fetchData = async () => {
    try {
      const response = await axios.get(`/apiblogs/homeBlogs`);
      const result = response.data;

      if (result && result.listTopics) {
        setData(result.listTopics); // Save blog data to state
      } else {
        setError("No topics found");
      }

      if (result && result.listTopicImages) {
        setImg(result.listTopicImages); // Save blog images to state
      } else {
        setError("No Image found");
      }

      if (result && result.categories) {
        setCat(result.categories); // Save categories to state
      } else {
        setError("No categories found");
      }
    } catch (error) {
      setError(error.message); // Catch and display error message if any
    }
  };

  // Fetch blogs based on selected category
  const fetchBlogsByCategory = async (categoryId) => {
    try {
      const response = await axios.get(`/apiblogs/get/${categoryId}`);
      const resultCategories = response.data;

      setData(resultCategories.listTopics); // Update the data with fetched category blogs
      setImg(resultCategories.listTopicImages);
      setCat(resultCategories.categories);
    } catch (err) {
      console.error(err);
    }
  };

  // Handle category click
  useEffect(() => {
    if (categoryId) {
      fetchBlogsByCategory(categoryId);
      setActiveCategory(categoryId);

    } else {
      fetchData(); // Fetch blogs and categories on mount
    }
  }, [categoryId]);



  // const handleCategoryChange = (categoryId) => {
  //   fetchBlogsByCategory(categoryId); // Fetch blogs by the selected category ID
  // };

  const handleCategoryChange = (categoryId) => {
    fetchBlogsByCategory(categoryId);
    setActiveCategory(categoryId);
  };



  const filteredData = categoryId
    ? data.filter((blog) => blog.category === categoryId)
    : data;

  const combinedData = data.map((d) => {
    const imgItem = img.find((i) => i.topicId === d.id);
    return imgItem ? { ...d, ...imgItem } : d;
  });


  return (
    <div className="container-fluid">
      {/* Category Section */}
      <div className="category-section mb-4">
        <div className="text-center mt-3 p-3" >

          <div className="row d-lg-flex justify-content-lg-center">
            {cat.map((category) => (
              <div className="col-auto mb-2 categoryButton" key={category.id}>
                <Link
                  to={`/NewsArticle/${category.category}`}
                  onClick={() => handleCategoryChange(category.category)}
                  className={`category-text ${activeCategory === category.category ? 'active-category' : ''}`}
                >
                  {category.category}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container">

        {/* Blog Section */}
        <div className="blog-section">
          <h3 className="text-center">Latest Blogs</h3>
          <div className="row">
            {combinedData.map((blog) => (
              <div className="col-md-4 mb-4" key={blog.id}>
                <div className="cards shadow-sm" style={{ height: '100%', width: '100%', position: 'relative' }}>
                  {/* Blog Image */}
                  <a href={`/BlogDetail/${encodeURIComponent(blog.heading)}`} className="blog-link">
                    <img
                      src={`data:image/png;base64,${blog.image}`}
                      className="card-img-top"
                      alt="Blog Image"
                      style={{
                        height: '200px',
                        width: '100%',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />

                    {/* Blog Details that appear on hover */}
                    <div className="card-details">
                      <h5 className="card-title text">{blog.heading}</h5>
                      <p className="card-date date">{blog.date}</p>
                      <a href={`/BlogDetail/${encodeURIComponent(blog.heading)}`} className="btn btn-light">
                        Read More
                      </a>
                    </div>
                  </a>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>


  );
};

export default NewsArticle;
