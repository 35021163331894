import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";
import $ from "jquery";
import "../assets/css/Fssai.css";
import fassaiImage from "../static/image/img/fassai.jpg";
import Fssaiback from "../static/assetse/img/fassaiBack.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"

const Fssai = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="FSSAI License" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/Fssai" data-react-helmet="true" />

        <meta property="og:description" content="FSSAI stands for Food Safety and Standards Authority of India. Obtain a mandatory FSSAI license for your food business to ensure quality checks and improve accountability. Learn about registration through TaxOSmart." />


      </Helmet>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">FSSAI Food License</h1>
              <p className="fs-5">Online process</p>

              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="spanGet">Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={Fssaiback} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 >FSSAI Basic registration:</h4>
                <p className="description">
                  This is required for small business or startups which have
                  annual turnover below Rs.12 lakhs. Basic registration can be
                  upgraded to state license as your sales graph increases.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-cube"></i>
              </div>
              <div>
                <h4 >FSSAI State license:</h4>
                <p className="description">
                  This license is applicable to mid-sized companies which have
                  annual turnover between Rs.12-20 crores.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className=" fa fa-coffee"></i>
              </div>
              <div>
                <h4 >FSSAI Central license:</h4>
                <p className="description">
                  This license is applicable typically on large business with
                  annual turnover above Rs.20 crores. It is also required in
                  cases where you need to supply at the government offices or
                  import / export food products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={fassaiImage} className="img-fluid image" alt="" />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>What Is FSSAI?</h2>
              </div>
              <p>
                FSSAI is an abbreviation used for Food Safety and Standards
                Authority of India. FSSAI license is mandatory before starting
                any food business. All the manufacturers, traders, restaurants
                who are involved in food business must obtain a 14-digit
                registration or a license number which must be printed on food
                packages.
              </p>
              <p>
                This step is taken by government to ensure that food products
                undergo certain quality checks, thereby reducing the instances
                of adulteration, substandard products and improve accountability
                of manufacturers. FSSAI Registration is done through TaxOSmart.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header secType mt-0 !important">
            <h2>Types of FSSAI License</h2>
            <p>
              Businesses engaged in food activities are required to apply for
              different type of food license based on turnover, scale of
              business and the type of activity. Such businesses must apply for
              either of the licenses (central or state) or simple registration.
              The criteria specified in rules are as follows:
            </p>
          </div>

          <div className="row gy-4">
            <div className="col-lg-12 col-md-6">
              <div className="card card-hover">
                <div className="card-img">
                  {/* <img
                  src="assets/img/storage-service.jpg"
                  alt=""
                  className="img-fluid"
                /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    FSSAI Basic registration:
                  </a>
                </h3>
                <p>
                  This is required for small business or startups which have
                  annual turnover below Rs.12 lakhs. Basic registration can be
                  upgraded to state license as your sales graph increases.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-12 col-md-6">
              <div className="card card-hover">
                <div className="card-img">
                  {/* <img
                  src="assets/img/logistics-service.jpg"
                  alt=""
                  className="img-fluid"
                /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    FSSAI State license:
                  </a>
                </h3>
                <p>
                  This license is applicable to mid-sized companies which have
                  annual turnover between Rs.12-20 crores.
                </p>
              </div>
            </div>

            <div className="col-lg-12 col-md-6">
              <div className="card card-hover">
                <div className="card-img">
                  {/* <img
                  src="assets/img/cargo-service.jpg"
                  alt=""
                  className="img-fluid"
                /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    FSSAI Central license:
                  </a>
                </h3>
                <p>
                  This license is applicable typically on large business with
                  annual turnover above Rs.20 crores. It is also required in
                  cases where you need to supply at the government offices or
                  import / export food products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal " >
                Fassai Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              
                 <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>

            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={fassaiImage}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Fssai")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px', fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px' }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>
    </div>
  );
};

export default Fssai;
