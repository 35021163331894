import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import "../assets/css/Epf.css";
import axios from "axios";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import EpfImg from "../static/img/epfImage.jpg";
// import storagService from "../assets/img/storage-service.jpg";
// import logisticService from "../assets/img/logistics-service.jpg";
import Epfjpg from "../static/image/img/epf.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const Epf = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Employees Provident Fund",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover how the Employees Provident Fund (EPF) serves as a key savings platform for employees across India, implemented by the Employees Provident Fund Organization (EPFO). Learn about its features and benefits, including its integration with TaxOSmart for seamless management.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover how the Employees Provident Fund (EPF) serves as a key savings platform for employees across India, implemented by the Employees Provident Fund Organization (EPFO). Learn about its features and benefits, including its integration with TaxOSmart for seamless management."
	}

  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="The Employees Provident Fund (EPF) is a vital savings platform for individuals working in government, private, and public sector organizations in India. Learn how EPF is implemented by the Employees Provident Fund Organization (EPFO) and how to register through TaxOSmart." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Employees Provident Fund" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/Epf" data-react-helmet="true" />

        {/* <meta name="keywords" content="EPF, Employees Provident Fund, EPFO, savings platform, government sector, private sector, TaxOSmart." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" /> */}

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
      >
        <div className="container epfTop">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">Employees Provident Fund</h1>
              <p>Mandatory for Companies with 20 Employees</p>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={EpfImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Call To Action Section --> */}

      <main id="main">
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Epfjpg} className="img-fluid" id="epfImg" alt="" />{" "}
                {/* </img> */}
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2 className="">Employees Provident Fund (EPF)</h2>
                </div>
                <p>
                  EPF is one of the main platform of savings in India for nearly
                  all people working in Government, Private or Public sector
                  organizations. It is implemented by the Employees Provident
                  Fund Organization (EPFO) of India. Employees Provident Fund is
                  done through TaxOSmart.
                </p>

                <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3" style={{color:"#001f8d"}}>What Is Included In Our Package?</h5>
                      <p>
                        Drafting Documents , Application Filing, Application
                        Processing, Completion of Registration
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features documentTop">
          <div className="container" data-aos="">
            <div className="section-header">
              <h2>Documents In Respect Of Entity</h2>
            </div>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>PAN Card copy of firm/company/society/trust</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Cancelled cheque (bearing preprinted name & a/c no)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Partnership deed (In case of partnership)</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Certificate of Registration (In case of Proprietorship/
                        Partnership)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Certificate of incorporation (In case of company/
                        society trust)
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* 
<!-- ======= Features Section ======= --> */}
        <section id="features" className="features documentTop">
          <div className="container" data-aos="">
            <div className="section-header">
              <h2>Documents In Respect Of Entity</h2>
            </div>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>PAN Card copy of firm/company/society/trust.</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Cancelled cheque (bearing preprinted name & a/c no)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Partnership deed (In case of partnership)</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Certificate of Registration (In case of Proprietorship/
                        Partnership)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Certificate of incorporation (In case of company/
                        society trust)
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Eligibility For Employees Provident Fund</h2>
          </div>

          <div className="row gy-4">
            <div
              className="col-lg-12 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-hover">
                <div className="card-img">
                  {/* <img src="" alt="" className="img-fluid" /> */}
                </div>

                <p>
                  At the time of joining an employee in the employment and
                  getting wages up to Rs. 15,000/- is required to become a
                  member.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-12 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card card-hover"  >
                <div className="card-img">
                  {/* <img src=" " alt="" className="img-fluid" /> */}
                </div>

                <p>
                  In this act, Wages implies and includes Basic + Dearness
                  Allowances, Cash value of food concession and retaining
                  allowances, if any.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

     
      {/* 

<!--/ End Single News -->

<!-- Modal --> */}
      {/* Success Modal */}

      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3 h-120">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" >
                Employee Provident Fund
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={EpfImg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Employees Provident Fund")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default Epf;
