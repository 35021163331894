import React, { useState } from "react";
import $ from "jquery";
import axios from "axios";
import CPReg from "../static/img/copyrightReg.png";
import CPW from "../static/img/copyrigh.webp";
import SucWeb from "../static/img/Succ.webp";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "react-bootstrap/Button";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css"; import { Helmet } from 'react-helmet';

const CopyRight = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Copyright Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the essentials of copyright registration in India under the Copyright Act of 1957. Learn how this legal framework protects creators' rights, including reproduction, adaptation, and public communication, ensuring the uniqueness of their work.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the essentials of copyright registration in India under the Copyright Act of 1957. Learn how this legal framework protects creators' rights, including reproduction, adaptation, and public communication, ensuring the uniqueness of their work."
	}

  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:title" content="Copyright Registration" data-react-helmet="true" />

        <meta property="og:description" content="Trademark objections in India occur when applications do not comply with legal standards. Common reasons include similarity to existing trademarks and violation of public sentiments. Learn how to effectively respond to objections within 30 days with guidance from TaxOSmart." />
        <meta name="keywords" content="Trademark Objection, trademark application, legal standards, respond to trademark objection, intellectual property, TaxOSmart" />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:url" content="http://www.taxosmart.com/CopyRight" data-react-helmet="true" />

        <meta name="author" content="Taxosmart" />

        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container copyTop">
          <div class="Topbox">
            <div class="topcontents">
              <h1 className="fs-1">Copyright Registration</h1>
              <p className="fs-5">Get objection drafted</p>
              <div data-aos="" data-aos-delay="600">
                <div class="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={CPReg} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= About Section ======= --> */}
      <section>
        <div class="container-fluied" data-aos="">
          <div class="row gx-0">
            <div
              class="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div class="container content">
                <header class="section-header">
                  <h2>Copyright Registration</h2>
                  <br />
                </header>
                <p className="mt-0">
                  Copyright registration in India is enrolled under Copyright
                  Act, 1957. It provides a kind of safety so that the work
                  carried out by the creator of work cannot be copied by anyone
                  and to restore the uniqueness of the product. There are
                  bundles of rights under Copyright like communication to the
                  public, the rights of reproduction, adaptation, and
                  translation of the work.
                </p>
                <p>
                  Copyright is basically a legal right which has been provided
                  to the creators of literature, dramatics, musical and artistic
                  work and even the producer's films and sound recordings.
                  Sometimes even businesses and startups get copyright
                  registration related to instruction manuals, product
                  literature and user guides. Usually, copyright is possessed by
                  a creator of the work, but sometimes even the employer of its
                  creator or the person who has authorised the work can own the
                  copyright.
                </p>
                <p>
                  Copyright registration can be done through TaxOSmart in Delhi
                  NCR, Mumbai, Bengaluru, Chennai & all other Indian cities
                </p>
                <p>
                  Just like Trademark and Patent, Copyright is also one of the
                  intellectual property protection. The Copyright Act, 1957
                  helps you in protecting the original or genuine literature,
                  drama, music and artistic work. And ideas, procedures or even
                  the methods of operation can never be copyrighted. In a
                  layman's language, a copyright is an exclusive right granted
                  to the owner in order to protect his work from being copied,
                  exploited or misused.
                </p>
                <p>
                  Doing a particular work takes lots of efforts and energy and
                  therefore protecting the work done is as important as doing a
                  work. Hence, one of the mediums through which one can protect
                  as well as enjoy certain rights over his/ her works is
                  Copyright.
                </p>
                <p>
                  The only owner of registered work can use the product or even
                  can grant permission to another person. Copyrights are also
                  transferrable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section id="features" class="features docsTop">
        <div class="container" data-aos="">
          <header class="section-header">
            <h2 class="mediaFont">What Is Included In Our Package?</h2>
          </header>
          <br />
          <div class="row">
            <div class="col-lg-12  mt-lg-0 d-flex">
              <div class="row align-self-center gy-4" style={{width:"100%"}}>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Application Drafting</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Application Filing</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Government Fees</h3>
                  </div>
                </div>

                <div class="col-md-6 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Diary Number</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>

      <section id="features" class="features adTop">
        <div class="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div class="row feature-icons aos-init aos-animate mt-4" data-aos="">
            <h3>Advantages Of Copyright Registration</h3>

            <div class="row Legaldiv">
              <div class="col-xl-12 d-flex content">
                <div class="row align-self-center gy-4">
                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-desktop" aria-hidden="true"></i>

                    <div>
                      <h4>Legal Protection</h4>
                      <p>
                        The creators or owners of original work can always
                        protect their work through copyright registration. It
                        helps them against infringement.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i>

                    <div>
                      <h4>Market Presence</h4>
                      <p>
                        With copyright registration, your creative work will get
                        promoted among mass in a single go. It can be easily
                        used for publicising or for marketing in the mind of the
                        customer.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i class="fa fa-suitcase" aria-hidden="true"></i>

                    <div>
                      <h4>Rights of the Owner</h4>
                      <p>
                        Rights over dissemination, translation, reproduction and
                        adaptation of work have been fully assigned to the
                        creator of work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-handshake" aria-hidden="true"></i>

                    <div>
                      <h4>Protection</h4>
                      <p>
                        Copyright registration helps you in protecting your
                        unique work and will act as an evidence in the court of
                        law over ownership of the work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i
                      class="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>

                    <div>
                      <h4>Restricts unauthorized Reproduction</h4>
                      <p>
                        It restricts to produce duplicity of the unique work and
                        give more preference to the owner of work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>

                    <div>
                      <h4>Creation of Asset</h4>
                      <p>
                        It generates an intellectual property. Registered
                        copyrights can be sold, commercially contracted or
                        franchised with the prior permission of the owner.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <section id="features" class="features docsTop">
        <div class="container" data-aos="">
          <header class="section-header">
            <h2 class="mediaFont">
              What All Things Are Protected Under A Copyright?
            </h2>
            <p>
              It basically protects the expression of ideas (e.g. words &
              illustrations), alone it cannot be protected. The following may be
              protected under copyright law:
            </p>
          </header>
          <br />

          <div class="row">
            <div
              class="col-lg-12 mt-5 mt-lg-0 d-flex 
            whyRecon"
            >
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Literary works (e.g., written books, computer programs,
                      website)
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Dramatic works (e.g., scripts for films and dramas)</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Musical works (e.g., melodies)</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Artistic works (e.g., paintings, photographs)</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Published editions</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Recorded Sounds</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Movies, Films and telefilms</h3>
                  </div>
                </div>
                <div class="col-md-6 mb-lg-0 mb-3" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Broadcasts on Radio and Television</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features adTop">
        <div class="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div
            class="row feature-icons aos-init aos-animate mt-0 pt-4"
            data-aos=""
          >
            <h3>Details Required For Copyright Registration</h3>

            <div class="row mt-6">
              <div class="col-xl-12 d-flex content">
                <div class="row align-self-center gy-4 mt-4">
                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-user" aria-hidden="true"></i>

                    <div>
                      <h4>Personal details</h4>
                      <p>
                        This includes the Name, Address and Nationality of
                        Applicant. Applicant should specify the nature. Whether
                        he is the owner or the representative?
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i class="fa fa-desktop" aria-hidden="true"></i>

                    <div>
                      <h4>Nature of the work</h4>
                      <p>
                        This includes class & description of the work, title of
                        the Work. In case of a website copyright, give the URL
                        of the website. You also need be mention the language of
                        the Work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i class="fa fa-clock-o" aria-hidden="true"></i>

                    <div>
                      <h4>Date of Publication</h4>
                      <p>
                        Mention the date of Publication in internal magazines
                        (if possible). Like a company magazine or a research
                        paper submitted to a professor does not count as
                        publication
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <br />
      {/* <!-- End About Section --> */}

     
      {/* <!--/ End Single News -->

      {/* Success Modal */}

<div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              
            >
              <h3 className="modal-title headerModal" >
                Copyright Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CPReg}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Copy Right")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div class="modal-content">
            <div class="modal-header">
              <h4>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span class="headingMsg"></span>
              </h4>
            </div>
            <div class="modal-body">
              <div class="d-flex">
                <div class="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="col-sm-9">
                  <div class="msg"></div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" class="modal" role="dialog">
        <div class="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div class="modal-content">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>
                  <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Copy Right Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CopyRight}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)})</script>
    </div>
  );
};
export default CopyRight;
