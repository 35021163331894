import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/CopyRight.css";
import patentReg from "../static/assetse/img/patentRegistration.jpg";
import PatentSearch from "../static/image/img/patentSearch.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { Helmet } from 'react-helmet';
const PatentRegistration = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Smart GST Reconciliation Software | Streamline Your GST Compliance",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover how patent registration grants intellectual property rights to inventors in India. Learn about the role of the IP department, the requirements for uniqueness, and the legal framework established by the Patent Act of 1970 and Patent Rules of 1972. Secure your invention and gain preferential rights today.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover how patent registration grants intellectual property rights to inventors in India. Learn about the role of the IP department, the requirements for uniqueness, and the legal framework established by the Patent Act of 1970 and Patent Rules of 1972. Secure your invention and gain preferential rights today."
	}


  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="Patent registration in India provides intellectual property rights for unique inventions. Governed by the Patent Act of 1970 and Patent Rules of 1972, it allows inventors to secure their innovations with the Indian government's support." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Taxosmart | Technology empowering your business" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/PatentRegistration" data-react-helmet="true" />

        <meta name="keywords" content="Patent Registration in India" />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="call-to-action" className="call-to-action">
        <div className="container" data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              {/* <!-- <h3>Patent Registration</h3>
        <p>Protect your Invention</p> --> */}
        <h3>Patent Registration</h3>
              <a
                className="cta-btn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                href=""
                data-bs-backdrop="false"
              >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}
      <br />
      <main id="main">
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first mt-0">
                <img src={patentReg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2>Patent Registration</h2>
                </div>
                <p>
                  A Patent registration helps in getting an intellectual
                  property right to an invention carried out by an individual or
                  firm. IP department has been initiated by Indian government to
                  grant you the full right to register your invention under
                  patent (but only if it is unique). In return the inventor must
                  produce all the proofs related to the invention as asked by
                  the government. It also ensures that owner gets more
                  preference over other person for your particular invention. In
                  India, Patent is being governed by the Patent Act 1970 &
                  Patent Rules 1972.
                </p>

                <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3" style={{color:"#012970"}}>What Is Included In Our Package?</h5>
                      <p>
                        Government Registration Fees , Application Preparation,
                        Obtain MSME Certificate
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features patentTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2 className="section-header">
                What Is Included In Our Package?
              </h2>
            </header>

            <div className="row">
              {/* <!--  <div className="col-lg-6">
          <img src="static/assets/img/features.png" className="img-fluid" alt="">
        </div> --> */}

              <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4" style={{width:"100%"}}>
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Prior Art Search</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Application Drafting</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Application Filing</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3 "
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Government Fees</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features patentTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2>Why Patent Registration?</h2>
              {/* <!--   <p>Laboriosam et omnis fuga quis dolor direda fara</p> --> */}
            </header>

            <div className="row">
              {/* 
        <!--  <div className="col-lg-6">
          <img src="static/assets/img/features.png" className="img-fluid" alt="">
        </div> --> */}

              <div className="col-lg-12  mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Get Royalty by licensing your patent</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Protection For a period of 20 Years In India</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>you can then Utilize your Invention Yourself</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <br />
      <section id="features" className="features patentTop">
        <div className="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div
            className="row feature-icons aos-init aos-animate mt-0"
            data-aos=""
          >
            <h3>Steps For Patent Application</h3>

            <div className="row mt-0">
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4 mt-0">
                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="fa fa-coffee" aria-hidden="true"></i>

                    <div>
                      <h4>Step 1: Patent search-</h4>
                      <p className="text-justify">
                        A thorough search is performed for all the existing
                        inventions on the idea that you are trying to patent. If
                        there is a patent already on the idea you are
                        considering, than patent is not granted. Patent search
                        saves you from the effort of going through the year long
                        registration process. You can stop if the patent is
                        already registered.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 icon-box aos-init aos-animate">
                    <i className="fa fa-bookmark" aria-hidden="true"></i>

                    <div>
                      <h4>Step 2: Patent domicile-</h4>
                      <p className="text-justify">
                        Patent which are registered in India is only valid for
                        India. It protects your invention in India only and does
                        not apply to other countries. There is the possibility
                        to protect your invention in other countries as well.
                        For same, you need to apply for a separate application
                        in each country.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="fa fa-life-ring" aria-hidden="true"></i>

                    <div>
                      <h4>Step 3: File Patent application-</h4>
                      <p className="text-justify">
                        The Patent office of the Indian government reviews your
                        patent application. There check for any existing patents
                        granted on similar idea. If they find the invention
                        unique and patentable, then they grant patent for the
                        application.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="fa fa-gavel" aria-hidden="true"></i>

                    <div>
                      <h4>Step 5: Patent Grant-</h4>
                      <p className="text-justify">
                        Once the patent is granted, the application status is
                        updated online at the Patent site. It can take from 6
                        months to even 1.5 year for a patent certificate to be
                        granted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

     
      {/* Success Modal */}

      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      {/* <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
             
            >
              <h3 className="modal-title headerModal" >
                TDS SOFTWARE
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}

<button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={patentReg}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Patent Registeration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default PatentRegistration;
