import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import axios from "axios";
import "../assets/css/ProfessionalTR.css";
import ProfessionalTax from "../static/assetse/img/professional-tax.jpg";
// import ProfTax from "../assets/profTaxs.jpg";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const ProfessionalTR = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Professional Tax Registration ",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Professional Tax is the tax levied by the various State Governments of India on salaried individuals working in government or non-government entities, or in practice of any profession, such as CAs, Lawyers, Doctors, etc. or carry out some form of business, are required to pay this professional tax.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Professional Tax is the tax levied by the various State Governments of India on salaried individuals working in government or non-government entities, or in practice of any profession, such as CAs, Lawyers, Doctors, etc. or carry out some form of business, are required to pay this professional tax."
	}

  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="Professional Tax is a tax levied by various State Governments in India on salaried individuals and professionals such as CAs, lawyers, and doctors. Learn about Professional Tax Registration and how to register through TaxOSmart." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Professional Tax Registration" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/ProfessionalTR" data-react-helmet="true" />

        <meta name="keywords" content="Professional Tax, tax registration, salaried individuals, state government tax, TaxOSmart." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container profTop">
          <div className="Topbox">
            <div className="topcontent" style={{width:"100%"}}>
              <h1 className="fs-1">Professional Tax Registration </h1>

              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={ProfessionalTax} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Call To Action Section --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}

        <section id="features" className="features">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up" style={{display:"flex",justifyContent:"center"}}
            >
              {/* <div className="col-md-5">
                <img src={ProfTax} className="img-fluid mt-0" alt="" />
              </div> */}
              <div className="col-md-7 mt-0">
                <div className="section-header">
                <h2 className="fs-1">Professional Tax Registration</h2>
                </div>
                <p className="fst-italic">
                  Professional Tax is the tax levied by the various State
                  Governments of India on salaried individuals working in
                  government or non-government entities, or in practice of any
                  profession, such as CAs, Lawyers, Doctors, etc. or carry out
                  some form of business, are required to pay this professional
                  tax.
                </p>

                <p>Professional Tax Registration is done through Tax-O-Smart.</p>
                <div className="section-header">
                <h2 className="mediaFont">What Is Included In Our Package?</h2>
                </div>
                <ul className="mobCol" style={{display:"flex",justifyContent:"center",gap:"10px",alignItems:"center"}}>
                  <li>
                    <i className="bi bi-check"></i>Tax Consultation
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Government Fees
                  </li>
                  <li style={{paddingBottom:"10px"}}>
                    <i className="bi bi-check"></i>  Documentation
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Features Item --> */}

            <div
              className="row gy-4 align-items-center features-item mt-0"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2 mt-0">
                <img src={ProfessionalTax} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <div className="section-header">
                <h2 className="fs-1">
                  More About Professional Tax Registration
                </h2>
                </div>
                <p>
                  Professional tax is imposed at the state level in India.
                  Different states have different rates and methods of
                  collection. It is not imposed in all states. The states which
                  impose professional tax are Andhra Pradesh, Assam,
                  Chhattisgarh, Gujarat, Karnataka, Kerala, Maharashtra, Madhya
                  Pradesh, Meghalaya, Odisha, Sikkim, Tamil Nadu, Telangana,
                  Tripura, and West Bengal. Professional Tax is deducted from
                  the salary of the salaried employees by the employers and same
                  is deposited with the State Government.
                </p>

                <p>
                  Professional tax is levied by Municipal Corporations of
                  particular state and majority of the Indian states impose this
                  duty. The professional tax is a source of revenue for the
                  State Government which helps them in implementing schemes for
                  the welfare and development of the region. It is also payable
                  by members of staff employed in private companies. It is
                  mandatory to pay professional tax. The rate of professional
                  tax charged is based on the Income Slabs set by the respective
                  State Governments. The tax amount of professional tax paid
                  during the year is allowed as Deduction under the Income Tax
                  Act
                </p>
              </div>
            </div>
            {/* <!-- Features Item --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features whatIncl">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2 className="mediaFont fs-3">What Is Included In Our Package?</h2>
            </header>

            <div className="row">
              <div className="col-lg-12  mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4" style={{width:"100%"}}>
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Tax Consultation</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Documentation</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3"
                    data-aos="zoom-out"
                    data-aos-delay="350"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Government Fees</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <br />
     
      {/* 
<!--/ End Single News -->

<!-- Modal --> */}
      {/* Success Modal */}

      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>




      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              
            >
              <h3 className="modal-title headerModal" style={{fontSize:"24px",whiteSpace:"nowrap"}} >
                Professional tax Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={ProfessionalTax}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Professional Tax")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Professional Tax Registeration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={ProfessionalTax}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default ProfessionalTR;
