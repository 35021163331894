import React, { useState } from "react";
import $ from "jquery";
import axios from "axios";
import "../assets/css/TrademarkObjection.css";
import TradeOjWeb from "../static/assetse/img/tradObjection.webp";
import trademarkAs from "../static/image/img/TrademarkAssign.jpg";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';

const TrademarkObjection = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const [isVisible, setIsVisible] = useState(true);
  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title></title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:description" content="Trademark objections occur when applications fail to meet legal norms. Common reasons include similarity to existing trademarks or religious sentiments. Learn how to respond to objections within 30 days and navigate the process with TaxOSmart." />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta property="og:title" content="Trademark Objection" data-react-helmet="true" />
        <meta property="og:url" content="http://www.taxosmart.com/TrademarkObjection" data-react-helmet="true" />

        <meta name="keywords" content="Trademark Objection, trademark application, legal norms, respond to objection, intellectual property, TaxOSmart." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="call-to-action" className="call-to-action ">
        <div className="container" data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3 className="fs-1">Trademark Objection</h3>

              <a
                className="cta-btn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                href=""
                data-bs-backdrop="false"
              >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}

      <main id="main">
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img
                  src={TradeOjWeb}
                  className="img-fluid"
                  id="respImgage"
                  alt=""
                />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <div className="section-header">
                <h2 className="fs-2">Trademark Objection</h2>
                </div>
                <p>
                  A Trademark is any unique expression related to a product or
                  service that distinguishes it from others. This expression
                  could be a word, slogan, photograph, logo, graphic, colour
                  combination, sound or even smell.
                </p>
                <p>
                  But due to some valid reasons, trademark offices can have
                  objections on any trademark application which does not meet
                  their legal norms. There can be many reasons for trademark
                  objection. It can be due to the similarity between logo or
                  word with any existing one. It may be due to the sentiments
                  attached with any religion. These are few reasons which can
                  lead to trademark objection. If you receive any objection like
                  this from another party, then you need to respond back within
                  a month, that is within 30 days. You will receive a proper
                  legal objection letter from Trademark department. But if you
                  still don't take any steps, then the Registrar has the full
                  rights to abandon the application.
                </p>
                <p>Trademark objection is done through TaxOSmart.</p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features whatIncTop">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2 className="mediaFont">What Is Included In Our Package?</h2>
            </header>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Consultation</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Objection Drafting</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Precedents Search</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Objection Filing</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Same Day Filing</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6 mb-lg-0 mb-3 "
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Government Fees</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <section id="about" className="about whatIncTop">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <header className="section-header">
                  <h2>Why Should You Respond Back To A Trademark Objection?</h2>
                  <br />
                </header>
                <p>
                  Always be well prepared before you receive any trademark
                  objection from the department. You should have appropriate
                  proofs which will prove that your trademark does not have
                  anything to do with the existing word or logo of another
                  person.
                </p>
                <p>
                  It provides your brand a unique identity as trademark
                  represents your company which helps you to stand out among
                  others
                </p>
                <p>It will provide your brand a special protection.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <br />
      
      {/* <!--/ End Single News --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
            
            >
              <h3 className="modal-title headerModal" >
                Trademark Objection Registration
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}

               <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>

            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={TradeOjWeb}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Trademark Objection")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}
                    >Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Trademaek Objection
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={TradeOjWeb}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>

      {/* <!-- jQuery library --> */}
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>

      {/* <!-- Latest compiled JavaScript --> */}
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    </div>
  );
};

export default TrademarkObjection;
