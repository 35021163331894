import axios from "axios";
import React, { useState } from "react";
import eInvoiceImage from "../static/img/Einvoicing.png";
import "../assets/css/eInvoice.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
const Einvoice = () => {

   // Structerd Data for SEO 
   const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Smart e-Invoicing Solution",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Businesses/Taxpayers with a turnover more than INR 50 crores in a financial year will have to implement the e-Invoicing process from 01st April 2021 mandatorily.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Businesses/Taxpayers with a turnover more than INR 50 crores in a financial year will have to implement the e-Invoicing process from 01st April 2021 mandatorily."
	}

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const [captchaToken, setCaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    email: '',
    mobile: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios.post('/apiCustomerDetail/', dataToSubmit)
      .then(response => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError('');
        setFormData({
          type: '',
          name: '',
          email: '',
          mobile: ''
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch(error => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };



  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box


  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };
  return (
    <div>
      <Helmet>
        <title>Smart e-Invoicing Solution</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {/* <meta name="description" content="Businesses/Taxpayers with a turnover more than INR 100 crores in a financial year must implement the e-Invoicing process from 1 January 2021. This process requires the submission of documents like GST invoices, debit/credit notes, and more to the Invoice Registration Portal (IRP) for validation and QR code generation." />
        <meta name="keywords" content="E-invoicing, smart e-invoicing, invoicing, invoice" />
        <meta name="author" content="Taxosmart" /> */}

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 

        <meta property="og:title" content="Smart e-Invoicing Solution" />
        <meta property="og:description" content="Implement the mandatory e-Invoicing process for businesses with a turnover exceeding INR 100 crores. Submit your invoices to the IRP for validation and receive a digital e-Invoice." />
        <meta property="og:url" content="https://www.taxosmart.com/Einvoice" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:src" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" /> 

        <meta name="twitter:title" content="Smart e-Invoicing Solution" />
        <meta name="twitter:description" content="Implement the mandatory e-Invoicing process for businesses with a turnover exceeding INR 100 crores. Submit your invoices to the IRP for validation and receive a digital e-Invoice." />
        <meta name="twitter:url" content="https://www.taxosmart.com/Einvoice" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/Einvoice" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container ">
          <div className="Topbox" style={{ marginTop: '-9%' }}>
            <div className="topcontent">
              <h1 className="InfoColor">Information About Smart E-INVOICING</h1>
              <p className="effort">
                Businesses/Taxpayers with a turnover more than INR 50 crores in
                a financial year will have to implement the e-Invoicing process
                from 01st April 2021 mandatorily.{" "}
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={eInvoiceImage}
                className="img-fluid gstImageCss"
                style={{ width: '100%', height: 'auto' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about" style={{ marginTop: "3%" }}>
        <div className="container-fluied">
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className="container content">
                <br />
                <div className="section-header">

                <h2
                  className="  slideInDown"
                  style={{ color: "#0f316d", textAlign: "center" }}
                  >
                  Introduction to E-invoicing
                </h2>
                  </div>
                
                <p className="busTop text-justify">
                  Businesses/Taxpayers with a turnover more than INR 50 crores
                  in a financial year will have to implement the e-Invoicing
                  process from 01st April 2021 mandatorily. Under this process,
                  businesses have to submit documents like GST invoices,
                  debit/credit notes, reverse change invoices, export invoices,
                  etc. to a centralised Government portal called the Invoice
                  Registration Portal (IRP). The IRP will validate the data in
                  these invoices and generate an Invoice Reference Number and QR
                  code for the same. It will digitally sign the invoice and send
                  back an e-Invoice to the taxpayers electronically.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
                <img src={eInvoiceImage} alt="" className="tdsImage" />
              </div>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTop">
        <div className="container">
        <div className="section-header">
          <h2
            className="   slideInDown"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            E-Invoicing Applicability
          </h2>
          </div>
          

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex" id="whyRecon">
              <div className="row align-self-center gy-4">
                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center" >
                    <i className="bi bi-check"></i>
                    <h3>
                      Has a turnover of INR 50 crores or more (based on PAN) in
                      the previous financial year.
                    </h3>
                  </div>
                </div>

                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Issues B2B invoices.</h3>
                  </div>
                </div>

                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center" >
                    <i className="bi bi-check"></i>
                    <h3>
                      Supplies goods or services or both to a registered
                      person(recipient or transporter of supplies cannot
                      generate e-Invoices)
                    </h3>
                  </div>
                </div>

                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Taxpayers undertaking Exports (with/without payment) and
                      Deemed Exports are liable to generate e-Invoices.
                    </h3>
                  </div>
                </div>

                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Supplies to SEZs (with/without payment) are covered under
                      e-Invoicing
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      SEZ Developers who have the specified turnover (while
                      fulfilling the other conditions) are required to generate
                      e-Invoices.
                    </h3>
                  </div>
                </div>
                <div className="col-md-6 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      DTA units are required to issue e-Invoices if other
                      guidelines are met.
                    </h3>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="table d-flex justify-content-center">
        <div className="container" data-aos="">
          <div
            className="table-responsive aos-init ng-scope aos-animate"
            data-aos=""
            style={{ width: "100%",borderRadius:"10px" }}
          >
            <table className="table table-bordered " >
              <thead>
                <tr>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#0F316D",
                      color: "#fff",
                    }}
                  >
                    CBIC Notified Timelines
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Turnover in INR at PAN level</td>
                  <td>E-Invoicing Applicable from</td>
                </tr>
                <tr>
                  <td>More than INR 500 crores</td>
                  <td>01st Oct, 2020</td>
                </tr>
                <tr>
                  <td>More than INR 100 crores</td>
                  <td>01st Jan, 2021</td>
                </tr>
                <tr>
                  <td>More than INR 50 crores</td>
                  <td>01st April, 2021</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTop">
        <div className="container" data-aos="">
          <div className="section-header">

          <h2
            className="   slideInDown "
            style={{ color: "#0f316d", textAlign: "center" }}
            >
            Taxapayers Exempted from E-Invoice
          </h2>
            </div>
         

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Insurance Company</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Transport service provider, providing passenger
                      transportation service
                    </h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Special Economic Zone (SEZ) units</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Free Trade & Warehousing Zones (FTWZ)</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      E-Invoicing is not applicable for import Bills of Entry
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Invoices issued by Input Service Distributors (ISD) are
                      not covered under E-Invoicing.
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      From an unregistered person (attracting reverse charge) or
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Through the import of services, e-invoicing is not
                      applicable
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTopOb">
      <div className="container" data-aos="">
  <div className="section-header">
    <h2
      className="slideInDown"
      style={{ color: "#0f316d", textAlign: "center" }}
    >
      Taxpayer's Obligation
    </h2>
  </div>

  <div className="row">
    <div className="col-md-12 d-flex  mt-5 mt-lg-0 mobCol  lg:mb-0 mb-2" style={{gap:"10px"}} >
      <div className="feature-box d-flex align-items-center col-md-4">
        <i className="bi bi-check"></i>
        <h3>Report the invoice details to the IRP</h3>
      </div>

      <div className="feature-box d-flex align-items-center col-md-4">
        <i className="bi bi-check"></i>
        <h3>Obtain IRN and</h3>
      </div>

      <div className="feature-box d-flex align-items-center col-md-4">
        <i className="bi bi-check"></i>
        <h3>Issue the final invoice with QR code</h3>
      </div>
    </div>
  </div>
</div>

      </section>

      <section id="features" className="features" style={{ marginTop: "0%" }}>
        <div className="container aos-init aos-animate" data-aos="">

          <div
            className="row feature-icons aos-init aos-animate"
            data-aos=""
            style={{ marginTop: "0%" }}
          >
            <div className="section-header">

            <h2>
              Multiple Data Integration offered by our E-Invoicing solution
            </h2>
            </div>

            <div className="row" style={{ marginTop: "0%" }}>
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4 decTop">
                  <div
                    className="col-md-6 "
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div className="card card-hover">
                      <h4>DATA API's</h4>
                      <p >
                        Send data for one invoice at a time to taxosmart and get
                        real time response
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i className="ri-stack-line"></i>
                    <div className="card card-hover" style={{height:"100%"}}>
                      <h4>Push file to SFTP:</h4>
                      <p>
                        Push files and get IRN either through portal or file
                        response
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div className="card card-hover" style={{height:"100%"}}>
                      <h4>Upload File APIs</h4>
                      <p>
                        Send CSV file containing one or multiple invoices
                        through APIs
                      </p>
                      <p></p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 "
                    data-aos=""
                    data-aos-delay="300"
                  >
                    <i className="ri-magic-line"></i>
                    <div className="card card-hover">
                      <h4>Manual Upload</h4>
                      <p>
                        Use taxosmart - E-invoicing Software to upload the CSV
                        file and generate IRN
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}

     
      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title headerModal" style={{ fontSize: "34px" }}>
               SMART E-INVOICING
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal"
									aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={eInvoiceImage}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Smart E-INVOICING")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{textAlign: "center", marginTop: "3%",display:"flex",justifyContent:"center" }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}
                     style={{width:"200px",borderRadius:"6px",display:"flex",alignItems:"center",justifyContent:"center"}}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Einvoice;
