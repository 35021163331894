import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "../assets/css/topicDetail.css";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
const TopicDetail = () => {
  const { heading } = useParams();
  const [topicData, setTopicData] = useState({});
  const [imageData, setImageData] = useState({});
  const [topicContent, setTopicContent] = useState({});
 
  const fallbackImageUrl = 'https://www.taxosmart.com/static/img/taxosmartLogo.jpg';
  const getDetail = async () => {
    try {
      const data = { heading: heading }
      const response = await axios.post(`/apiblogs/detail`, data);
      // console.log(response);
      const { topic, image, topicContent } = response.data;
      setTopicData(topic);
      setImageData(image);
      setTopicContent(topicContent);
    } catch (error) {
      console.error("Error:", error);
    }
  };  

  const sanitizedContent = DOMPurify.sanitize(topicContent.content || "");

  useEffect(() => {
    getDetail();
  }, [heading]);

  useEffect(() => {
    if (imageData.image) {
      console.log("OG Image:", `data:image/png;base64,${imageData.image}`);
      console.log("Twitter Image:", `data:image/png;base64,${imageData.image}`);
    } else {
      console.log("Image is not available or undefined");
    }
  }, [imageData]); 

  useEffect(() => {
    // Checking image availability
    console.log("Image Data:", imageData.image);

    // Use the blog image if available, otherwise fallback to default image

    const imageUrl = imageData.image ? `data:image/png;base64,${imageData.image}` : fallbackImageUrl;

    console.log("Image URL for Sharing:", imageUrl);

    // Set the Open Graph and Twitter meta tags with the correct image
    document.querySelector('meta[property="og:image"]').setAttribute('content', imageUrl);
    document.querySelector('meta[name="twitter:image"]').setAttribute('content', imageUrl);
  }, [imageData.image]);


  // const blogImage = imageData.image ? `data:image/png;base64,${imageData.image}` : 'https://www.taxosmart.com/static/img/taxosmartLogo.jpg';
  return (
    <div>


      <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
       <meta name="author" content="Taxosmart" />
        <title>{topicData.heading || "Blog Detail"}</title>

        {/* Static Description Meta Tag (for SEO and social media fallback) */}


        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={topicData.heading || "Blog Detail"} />
        <meta
          property="og:image"
          // content={blogImage}
          content={imageData.image ? `data:image/png;base64,${imageData.image}` : fallbackImageUrl} 
        />

        {/* Open Graph Description Tag (Add back og:description) */}
        <meta
          property="og:description"
          content={topicData.excerpt || "Read this insightful blog post for more information."}
        />
        {/* </br> */}
        <meta property="og:url" content={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={topicData.heading || "Blog Detail"} />
        <meta
          name="twitter:image"
          // content={blogImage}
          content={imageData.image ? `data:image/png;base64,${imageData.image}` : fallbackImageUrl} 
        />

        {/* Static Description for Twitter */}
        <meta
          name="twitter:description"
          content={topicData.excerpt || "Read this insightful blog post for more information."}
        />
         <link rel="canonical" href={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`} />
      </Helmet>


      <br />
      <br />
      <br />
      <main class="container mobBlog">
        <div class="row">
          {/* <h2 style={{ marginBottom: "10px", color: "#0f2f6a" }}>
            {topicData.heading || "No Title"}
          </h2> */}

          <article class="col-md-10 blog-post card mx-auto">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>


              <p class="text-muted" style={{ color: "#0f2f6a", marginBottom: "0px" }}>
                Posted on {topicData.date || "No Date"}

              </p>
              {/* Share Button with multiple platforms */}
              <div className="share-buttons">
                <FacebookShareButton
                  url={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`}
                  quote={topicData.heading}
                  hashtag="#TaxOSmart"
                  className="fa-icon"
                >
                  <div className="fa-icon">
                    <FacebookIcon size={32} round={true} />
                  </div>
                </FacebookShareButton>

                <TwitterShareButton
                  url={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`}
                  title={topicData.heading}
                  hashtags={['TaxOSmart']}
                  className="fa-icon"
                >
                  <div className="fa-icon">
                    <FontAwesomeIcon icon={faXTwitter} size="25px" style={{ color: 'black',marginTop:"10px" }} />
                  </div>
                </TwitterShareButton>

                <LinkedinShareButton
                  url={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`}
                  title={topicData.heading}
                  summary={topicData.excerpt}
                  className="fa-icon"
                >
                  <div className="fa-icon">
                    <LinkedinIcon size={32} round={true} />
                  </div>
                </LinkedinShareButton>

                <WhatsappShareButton
                  url={`https://www.taxosmart.com/BlogDetail/${encodeURIComponent(heading)}`}
                  title={topicData.heading}
                  className="fa-icon"
                >
                  <div className="fa-icon">
                    <WhatsappIcon size={32} round={true} />
                  </div>
                </WhatsappShareButton>
              </div>
            </div>
            {/* Remove Max-auto for align left a content */}
            <img
              src={`data:image/png;base64,${imageData.image}`}
              class="img-fluid"
              alt={imageData.altText || "Blog Image"}
              style={{ marginTop: "0%" }}
            />
            <br></br>{" "}
            {/* <p
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                padding: "20px 0px;",
              }}
            >
              {topicData.heading || "No Title"}
            </p> */}
            <h5
              className="card-text mt-4"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            ></h5>

          </article>
          {/* <aside class="col-md-4"> */}
          {/* <div class="p-3 mb-3 bg-light rounded">
                    <h4>Categories</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">1. Others</a></li>
                        <li><a href="#">2. Indirect Tax</a></li>
                        <li><a href="#">3. Direct Tax</a></li>
                        <li><a href="#">4. Compliance Due Date Chart</a></li>
                        <li><a href="#">5. Company Low</a></li>
                        <li><a href="#">6. Audit</a></li>
                    </ul>
                </div> */}

          {/* <div class="p-3 mb-3 bg-light rounded">
                    <h4>Archives</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">September 2024</a></li>
                        <li><a href="#">August 2024</a></li>
                        <li><a href="#">July 2024</a></li>
                    </ul>
                </div> */}
          {/* </aside> */}
        </div>
      </main>
    </div>
  );
};

export default TopicDetail;
