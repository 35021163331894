import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
const Blogs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    type: "TDS", // Set default type value
  });
  const [data, setData] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState([]); // Initialize as an empty array
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get("/apiblogs/homeBlogs");
      // console.log('Success:',response.data); // Log the entire response data
      const result = response.data; // Assuming JSON is the property containing the array
      setData(result.listTopics);
      setImg(result.listTopicImages);
    } catch (error) {
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("YOUR_API_ENDPOINT", formData);
      setSuccess("Form submitted successfully!");
      setSuccessModalVisible(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Render component
  if (error) return <p></p>;
  if (data.length == 0) return <p></p>; // Display loading state

  const combinedData = data.map((d) => {
    const imgItem = img.find((i) => i.topicId === d.id);
    return imgItem ? { ...d, ...imgItem } : d;
  });
  return (
    <div className="container">
      <Helmet>
        <title>Blogs</title>
        <meta name="description" content="A collection of blogs and articles" />
        <meta name="keywords" content="blogs, articles, topics" />
      </Helmet>

      <div className="row col-md-12 d-flex ">
        {data.length == 0 ? (
          <p></p>
        ) : (
          <div className="col-sm-12">
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              {combinedData.map((blog) => (
                <div class="card" key={blog.id}>
                  <div className="card-body">
                    <h5 className="card-title">{blog.id}</h5>
                    <h5 className="card-title">{blog.heading}</h5>
                    <h5 className="card-title">
                      <img
                        src={`data:image/png;base64,${blog.image}`}
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </h5>
                    <p className="card-text">
                      <strong>Description:</strong> {blog.description}
                    </p>
                    <a
                      href={`/BlogDetail/${blog.topicId}`}
                      className="btn btn-primary"
                    >
                      <strong>Number of Views:</strong> {blog.numberOfViews}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
