import axios from "axios";
import React, { useState } from "react";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import icon5 from "../static/assets/img/icons/icon-5.png";
import icon6 from "../static/assets/img/icons/icon-6.png";
import successtick from "../assets/img/successtick.png"
import remove from "../assets/img/remove.png"
import tdsPageImage from "../static/img/tds img.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/datepicker.css";
import "../assets/css/font-awesome.min.css";
import "../assets/css/owl-carousel.css";
import "../assets/css/responsive.css";
import "../assets/css/style.css";
import "../assets/css/tdsStyle.css";
import "../static/assets/css/bootstrap.min.css";
// import "../static/assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "../static/assets/css/all.min.css";
import "../static/assets/css/styleMin.css";
import "../static/assets/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css";
import { Helmet } from "react-helmet";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const TdsSoftware = () => {
  const title = "TDS Software";
  const description = "This is a dynamic description TDS Software";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: "TDS-O-SMART - Intelligent TDS Return Filing Software",
    author: {
      "@type": "Person",
      name: "Author Name",
    },
    datePublished: "2024-10-21",
    image: "https://taxosmart.com/public/static/img/taxosmartLogo.jpg",
    articleBody:
      "Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments ",
    publisher: {
      "@type": "Organization",
      name: "TAXOSMART",
      logo: {
        "@type": "ImageObject",
        url: "https://taxosmart.com/static/media/tds img.jpg",
      },
    },
    description:
      "Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments.",
  };

  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      // new code for captcha
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>{title}</title>
        <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" data-react-helmet="true" /> 
        <meta  property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="http://www.taxosmart.com/TdsSoftware" data-react-helmet="true" />

        <meta property="og:description" content={description} />

      </Helmet>



      {/* <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown ">
            TDS Software{" "}
          </h1>
          <div data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                data-bs-backdrop="false"
                className="btn-get-started scrollto d-inline-flex 
                align-items-center justify-content-center align-self-center"
              >
                <span className="getTouch">Get In Touch</span>
                <i className="bi bi-arrow-right white"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}

        <section
            id="hero"
            className="hero d-flex align-items-center wow fadeIn top5"
            data-wow-delay="0.1s"
          >
            <div className="container top0">
              <div className="Topbox">
                <div className="topcontent">
                  <h1 className="InfoColor text-lg-start" style={{fontSize:"60px"}} >
                  TDS Software{" "}
                  </h1>
                  
                  <div data-aos-delay="600">
                    <div className="text-center text-lg-start">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#demoModal"
                        data-bs-backdrop="false"
                        className="btn-get-started scrollto
                         d-inline-flex align-items-center justify-content-center 
                         align-self-center"
                      >
                        <span className="getTouch">Get In Touch</span>
                        <i className="bi bi-arrow-right white"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="topimg"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={tdsPageImage}
                    className="img-fluid gstImageCss"
                    alt=""
                    style={{ width: "70%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </section>

      <div className="container-xxl py-lg-5 py-2">
        <div className="container">
          <div
            className="text-center mx-auto   wow fadeInUp tdsColorFont section-header"
            data-wow-delay="0.1s" style={{marginBottom:"10%"}}
          >
            <h4 className=" serviceColor textDecoration">Our Services</h4>
            <h2 className="display-5 mb-4 informationColor">
              INFORMATION ABOUT TDS SOFTWARE
            </h2>
          </div>
        </div>
      </div>

      <main id="main">
        <section
          id="about"
          className="about tdsMarginTop"
          style={{ marginTop: "-7% !important" }}
        >
          <div className="container">
            <div className="row gx-0">
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <div className="content p-lg-0 p-3">
                  <h2 className="tdsh2">What Is TDS Software?</h2>
                  <p>
                    TDS Software is an Intelligent TDS Return Filing Software
                    built in state-of-the-art technology, complying with TDS/TCS
                    prescribed as per Income Tax Laws of India. Its a complete
                    TDS management software which ensures 100% TDS compliance by
                    creating eTDS statements, correction statements, printing
                    TDS certificates in Form 16/16A and ePayments.
                  </p>
                  <p>
                    Supports unlimited deductors /companies,deductees,employees.
                  </p>
                  <p>
                    Generates regular and revised returns for all forms and
                    quarters from FY 2007-08 onwards.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 d-flex align-items-center">
                <img src={tdsPageImage} alt="" className="tdsImage" />
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="container-xxl ">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp tdsContainer section-header"
            data-wow-delay="0.1s"
          >
            <h4 className=" featureColor">Features</h4>
            <h2 className="display-5 mb-4 onlineColor">Online Features</h2>
          </div>
          <div className="container-xxl ">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp tdsFile"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }} >
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="lg:ms-4 ms-0 ">
                          <h4 className="mobWidth" >
                            File Error Free TDS Returns in Just Few Minutes
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >Highest Features at Lowest Price Guarantee</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Supports unlimited deductors / companies, deductees,
                            employees
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp tdsFile"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            In built more than 500 types of TRACES level checks,
                            enabling early default warning
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon5} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >Easy to Import/Export files from Ms-Excel</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl ">
        <div className="container">
          <div
            className=" section-header text-center mx-auto  wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h4 className=" feaColor  featureColor">Features</h4>
            <h2 className="display-5 mb-4 offColor">Offline Features</h2>
          </div>
          <div className="container-xxl ">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeEffect"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Supports unlimited deductors / companies, deductees,
                            employees
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Generates regular and revised returns for all forms
                            and quarters from FY 2007-08 onwards
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >Preparing Lower deduction consumption report</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeEffect"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            In-built utility for Import / Export of data from
                            MSExcel/ TDS/ Conso File / TXT file generated from
                            other software
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon5} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Ease of use, simple navigation, Excel based with
                            ready to use template
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl ">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp fadeCss section-header"
            data-wow-delay="0.1s"
          >
            <h4 className=" feaColor  featureColor">Features</h4>
            <h2 className="display-5 mb-4 offColor">Default Notifier</h2>
          </div>
          <div className="container-xxl ">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Generate Short Deduction Report, Short Payment
                            Report
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Shows Detailed Report for Interest on Late Payment &
                            Interest on Late Deduction
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Calculate Fees u/s 234E for Late Filing of TDS/TCS
                            Return.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth">
                            All Reports can be Previewed / Printed or Exported
                            to excel
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon5} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Generate report of invalid PAN, lower deduction
                            certificate
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon6} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Compare challan's utilized amount with available
                            balance amount
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl ">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp fadeCss section-header"
            data-wow-delay="0.1s"
          >
            <h4 className="feaColor  featureColor ">Features</h4>
            <h2 className="display-5 mb-4 offColor ">Other Features</h2>
          </div>
          <div className="container-xxl ">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Useful tools like Rate Chart, Income Tax Calculator
                            etc. at your fingertips to help improve
                            productivity.
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >Record LOG of FVU files created</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Convert your regular FVU / Correction FVU
                            /Consolidated TDS file into MS-Excel.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >Registered with Income Tax Department as ERA</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center  mobCol" style={{ gap: "15px" }}>
                        <img className="flex-shrink-0" src={icon5} alt="Icon" />
                        <div className="lg:ms-4 ms-0">
                          <h4 className="mobWidth" >
                            Registered under TIN - NSDL's list of TDS softwares
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl mt-5">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{}}
            >
              <h3 className="modal-title headerModal" style={{}}>
                TDS SOFTWARE
              </h3>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button> */}
              <button type="button" className="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={tdsPageImage}
                  style={{ width: "100%", height: "auto", marginTop: "0%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ textAlign: "center", marginTop: "3%", display: "flex", justifyContent: "center" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%", display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                      style={{ width: "200px", borderRadius: "6px", display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div >
        {isVisible && (
        <Modal show={successModalVisible} onHide={handleCloseSuccess} >
        <div 
        className="modalbox" style={{borderRadius:"-1%"}}
       >
          {/* Close (cross) button
          <button 
          className="crossbtn"
          
            onClick={handleCloseSuccess} 
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer"
            }}
          >
            &times;
          </button> */}
      
          {/* Image centered above the message */}
          <div className="succimglayout" >
            <img  src={successtick} alt="Success" style={{ width: '20%', height: 'auto' }} />
          </div>
      
          {/* Success message */}
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Successful</h4>
            <p>
              Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
            </p>
          </div>
      
          {/* OK button */}
          <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
            <Button 
              type="button" 
              onClick={closePop}
              className="btndesign"
              style={{ padding: '10px 20px',  justifyContent:"center",fontSize: '16px',width:"60%",borderRadius:"6px" ,background:"#00ba00",outline:"none",color:"white",border:"none" }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
       
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
         <Modal show={errorModalVisible} onHide={handleCloseError}>
         <div 
           className="modalbox"
         
         >
          
          
       
           {/* Image centered above the message */}
           <div className="errimglayout" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
             <img 
               src={remove} 
               alt="Error" 
               style={{ width: '20%'}} 
             />
           </div>
       
           {/* Error message */}
           <div style={{ textAlign: 'center' }}>
             <h4 style={{ fontSize: '24px', margin: '10px 0' }}>Something Went Wrong!</h4>
             <p>
               {error} {/* This will dynamically show the error message */}
             </p>
           </div>
       
           {/* OK button */}
           <div style={{ textAlign: 'center', marginTop: '20px',display:"flex",justifyContent:"center" }}>
             <Button 
               type="button" 
               onClick={closePop}
              
               style={{
                 padding: '10px 20px',
                 fontSize: '16px',
                 width: "60%",
                 borderRadius: "6px",
                 background: "#ff4d4d", // Error color, red
                 outline: "none",
                 color: "white",
                 border: "none",
                 justifyContent:"center"
               }}
             >
               CLOSE
             </Button>
           </div>
         </div>
       </Modal>
        )}
      </div>

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
    </div>
  );
};
export default TdsSoftware;
